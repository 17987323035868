import React from 'react';
import styled from 'styled-components';
import { Form, Layout, Localization, Typography } from 'connex-cds';
import cn from 'classnames';
import { useTicketContext } from '../../../../TicketContext';
import { CustomerCommentButton } from './CustomerCommentButton';

const { Column, Row, Container } = Layout;
const { Subtitle } = Typography;
const { Translate } = Localization;

const Styled = styled(Column)`
  [data-testid='driverCommentValue'] {
    textarea {
      height: 70px;
      width: 100%;
      border: 0;
      background: transparent;
    }
  }

  .is-customer-comment-enabled {
    padding: 5px 10px;

    .comment-section {
      padding: 0px 5px;
    }
  }

  .material-compliance-enabled {
    padding: 5px 10px;

    .materialCompliance-section i.icon {
      margin-right: 0.5rem;
    }
  }

  .comments-desktop-container {
    padding: 5px 10px;
  }
`;

export const CommentsDesktop = ({ companySetup, handleCommentClick, materialComplianceEnabled, air, consistence }) => {
  const { values } = Form.useFormContext();
  const { finalized } = useTicketContext();

  return (
    <Styled className={cn('comments-desktop')}>
      <Row flex={1}>
        {companySetup.data?.isDriverCommentEnabled && (
          <Column>
            <Container flex={1} className={cn('comments-desktop-container')}>
              <Subtitle>
                <Translate stringId="driverComment" data-testid="driverCommentLabel" />
              </Subtitle>
              <div className={cn('comment-section')}>
                <div data-testid="driverCommentValue">{values?.comments.driver}</div>
              </div>
            </Container>
          </Column>
        )}
        {companySetup.data?.isCustomerCommentEnabled && (
          <Column>
            <Container flex={1} className={cn('is-customer-comment-enabled')} onClick={handleCommentClick}>
              <Subtitle>
                <Translate stringId="customerComment" data-testid="customerCommentLabel" />
              </Subtitle>
              <div className={cn('comment-section')}>
                {finalized &&
                !values?.comments.customer &&
                !(values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') ? (
                  <CustomerCommentButton onClick={handleCommentClick} values={values} />
                ) : (
                  <div data-testid="customerCommentValue">{values?.comments.customer}</div>
                )}
              </div>
            </Container>
          </Column>
        )}
        {materialComplianceEnabled && (air?.checkCompliance || consistence?.checkCompliance) && (
          <Column>
            <Container flex={1} className={cn('material-compliance-enabled')} onClick={handleCommentClick}>
              <Subtitle>
                <Translate stringId="compliance" data-testid="materialComplianceLabel" />
              </Subtitle>
              {air?.checkCompliance && (
                <div className={cn('materialCompliance-section')}>
                  <i
                    className={cn(`icon fa-light`, {
                      'fa-square-check': !!values?.productCompliance?.consistenceIsCompliant,
                      'fa-square': !values?.productCompliance?.consistenceIsCompliant,
                    })}
                  />
                  <Translate stringId="consistenceIsCompliantShort" data-testid="consistenceIsCompliantLabel" />
                </div>
              )}
              {consistence?.checkCompliance && (
                <div className={cn('materialCompliance-section')}>
                  <i
                    className={cn(`icon fa-light`, {
                      'fa-square-check': !!values?.productCompliance?.airContentIsCompliant,
                      'fa-square': !values?.productCompliance?.airContentIsCompliant,
                    })}
                  />
                  <Translate stringId="airContentIsCompliantShort" data-testid="airContentIsCompliantLabel" />
                </div>
              )}
            </Container>
          </Column>
        )}
      </Row>
    </Styled>
  );
};
