import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyRU_RU = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>Уведомление о конфиденциальности Command Alkon Incorporated</h4>
      </div>
      <div id="overview_content">
        <p>
          <em>Последнее изменение: 26 сентября 2023 г.</em>
        </p>
        <h2>Обзор</h2>
        <p>
          Command Alkon Incorporated, включая связанные с ней компании («CAI» или «мы»), является международной
          коммерческой организацией, которая предоставляет программное обеспечение, услуги и веб-решения компаниям в
          индустрии готовых смесей, агрегатов и перевозки навалочных грузов с использованием локальных, программных
          решений как услуги («SaaS») и других веб-методов. CAI уважает конфиденциальность пользователей своих продуктов
          и услуг и стремится защищать их конфиденциальность и поддерживать их доверие. Это Уведомление о
          конфиденциальности описывает наши практики по сбору, использованию, хранению, передаче, раскрытию и иному
          обработке персональных данных (иначе называемых Персональной информацией) в связи с вашим использованием
          продуктов и/или услуг CAI, веб-сайтов, приложений и страниц в социальных сетях, а также вашими
          взаимодействиями с сотрудниками CAI на встречах и мероприятиях CAI, включая офлайн-продажи и маркетинговую
          деятельность. Пожалуйста, внимательно прочитайте это Уведомление о конфиденциальности, чтобы понять наши
          политики и практики в отношении обработки персональных данных. Получая доступ к нашему веб-сайту и/или
          используя наши продукты или услуги, вы соглашаетесь с нашим сбором, использованием и раскрытием персональных
          данных, как описано в этом Уведомлении о конфиденциальности. Если у вас есть вопросы или проблемы, связанные с
          конфиденциальностью, пожалуйста, свяжитесь с нами, используя информацию в разделе «Свяжитесь с нами» внизу
          этого Уведомления о конфиденциальности.
        </p>
        <h2></h2>
        <h2>Содержание</h2>
        <ul>
          <li>
            <a href="#summary_of_privacy_notice">Краткое уведомление о конфиденциальности</a>
          </li>
          <li>
            <a href="#controller_processor">Контроллер/Процессор</a>
          </li>
          <li>
            <a href="#to_whom_does_this_notice_apply">Кому применяется это уведомление</a>
          </li>
          <li>
            <a href="#how_we_collect_personal_data">Как мы собираем и используем персональные данные</a>
          </li>
          <li>
            <a href="#automatically_collected_information">Автоматически собранные данные</a>
          </li>
          <li>
            <a href="#how_we_use_the_information_we_collect">Как мы используем собранные данные</a>
          </li>
          <li>
            <a href="#how_we_disclose_the_information_we_collect">Как мы раскрываем собранные данные</a>
          </li>
          <li>
            <a href="#third_party_services">Сторонние сервисы</a>
          </li>
          <li>
            <a href="#security">Безопасность</a>
          </li>
          <li>
            <a href="#retention_of_data">Сохранение данных</a>
          </li>
          <li>
            <a href="#your_rights_and_choices">Ваши права и выборы</a>
          </li>
          <li>
            <a href="#international_data_transfers_and_the_data_privacy_framework">
              Международные передачи данных и рамки конфиденциальности данных
            </a>
          </li>
          <li>
            <a href="#lawful_basis_for_processing">Законные основания для обработки</a>
          </li>
          <li>
            <a href="#children_policy">Конфиденциальность детей</a>
          </li>
          <li>
            <a href="#changes_to_the_privacy_notice">Изменения в уведомлении о конфиденциальности</a>
          </li>
          <li>
            <a href="#contact_us">Свяжитесь с нами</a>
          </li>
        </ul>
      </div>
      <div id="summary_of_privacy_notice">
        <h1>Краткое уведомление о конфиденциальности</h1>
        <p>
          <strong>Применение</strong> &ndash; Наше Уведомление о конфиденциальности применяется к обработке персональных
          данных, предоставленных: 1) посетителями и пользователями различных сайтов, приложений, продуктов и/или услуг
          CAI; 2) участниками мероприятий CAI и отраслевых мероприятий; 3) клиентами и потенциальными клиентами и их
          представителями; 4) подписчиками на наши уведомления и/или рассылки; 5) поставщиками и деловыми партнерами и
          их представителями; 6) посетителями наших офисов; и 7) любыми другими лицами, предоставляющими персональные
          данные CAI для любых других целей. Для получения дополнительной информации см. ниже.
        </p>
        <p>
          <strong>Типы обрабатываемых данных</strong> &ndash; CAI собирает персональные данные, которые вы
          предоставляете. Например, когда вы регистрируетесь для создания учетной записи, мы можем собирать такие
          данные, как имена, адреса электронной почты, физические адреса, номера телефонов и т. д. Вы также можете
          предоставить персональные данные, например, чтобы узнать больше о наших продуктах или подписаться на наши
          уведомления. Мы можем обрабатывать следующие персональные данные, которые мы можем (в зависимости от
          обстоятельств) собирать во время посещений веб-сайтов, маркетинговых взаимодействий, использования приложений
          и использования наших продуктов и/или услуг в ходе заключения соглашений с вами и/или вашим работодателем:
          ваше имя, контактная информация (адрес электронной почты, номер телефона и т. д.), информация о работе
          (работодатель, должность, руководитель и т. д.), определенные идентификационные номера (водительское
          удостоверение, сотрудник и т. д.), информация о производительности работы и сертификации, платежная
          информация, IP-адрес, геолокация, подпись, изображение с камеры, имя пользователя и пароль, информация о
          профсоюзе, этническая принадлежность, некоторая медицинская информация, связанная с работой, сообщения и
          поведенческие данные, а также информация о вас, полученная через размещение файлов cookie и пикселей
          отслеживания во время использования платформы. Для получения дополнительной информации см. ниже.
        </p>
        <p>
          <strong>Цель обработки</strong> &ndash; Мы обрабатываем ваши персональные данные для следующих целей: 1)
          выполнение соглашений с вами и/или вашим работодателем; 2) мониторинг, разработка, поддержка и
          администрирование приложений, веб-сайтов и платформ; 3) безопасность и предотвращение мошенничества; 4) наши
          маркетинговые цели (мы не продаем ваши персональные данные); 5) наше деловое администрирование; и 6)
          поведенческий анализ, связанный с использованием платформы. Для получения дополнительной информации см. ниже.
        </p>
        <p>
          <strong>Законные основания для обработки</strong> &ndash; В зависимости от применимости к вам, некоторые
          юрисдикции требуют, чтобы любая обработка персональных данных поддерживалась законным основанием. Мы обычно
          полагаемся на следующие законные обоснования для наших действий по обработке: 1) выполнение соглашения с вами
          и/или вашим работодателем; 2) преследование наших законных интересов, если они не перевешивают ваши интересы,
          права и свободы; 3) ваше согласие; и 4) соблюдение юридических обязательств. Для получения дополнительной
          информации см. ниже.
        </p>
        <p>
          <strong>Передача данных</strong> &ndash; Мы можем передавать ваши персональные данные другим аффилированным
          лицам CAI и/или третьим сторонам (например, деловым партнерам, реселлерам и т. д.), приобретающим или
          приобретенным организациям, поставщикам услуг и, в соответствии с применимым законодательством,
          государственным органам, судам, внешним консультантам и аналогичным третьим сторонам. Некоторые передачи
          данных могут пересекать национальные границы. Мы будем использовать все разумные меры для обеспечения
          конфиденциальности ваших персональных данных при передаче. Мы не продаем ваши персональные данные третьим
          сторонам. Кроме того, CAI не будет делиться вашими данными с третьими сторонами без полного раскрытия, за
          исключением случаев, предусмотренных в этом Уведомлении о конфиденциальности. Для получения дополнительной
          информации см. ниже.
        </p>
        <p>
          <strong>Сохранение и удаление данных</strong> &ndash; Ваши персональные данные будут удалены, как только они
          больше не будут нужны для целей первоначальной обработки, законного интереса или в соответствии с применимым
          законодательством. Для получения дополнительной информации см. ниже.
        </p>
        <p>
          <strong>Ваши права и выборы</strong> &ndash; В зависимости от вашей юрисдикции, у вас может быть ряд прав в
          отношении ваших персональных данных, которые могут включать право на доступ к вашим персональным данным, право
          на получение копии ваших персональных данных, право на передачу ваших персональных данных третьей стороне,
          право на исправление ваших персональных данных, право на ограничение определенной обработки и/или право на
          удаление ваших персональных данных. Чтобы воспользоваться своими правами, связанными с вашими персональными
          данными, пожалуйста, используйте информацию в разделе «Свяжитесь с нами» внизу этого Уведомления о
          конфиденциальности. Для получения дополнительной информации см. ниже.
        </p>
        <p>
          <strong>Изменения в этом Уведомлении о конфиденциальности</strong> &ndash; Мы оставляем за собой право
          изменять условия этого Уведомления о конфиденциальности по своему усмотрению и в любое время в соответствии с
          изменяющимися практиками или законодательством о конфиденциальности. Текущая версия этого Уведомления о
          конфиденциальности всегда будет доступна по ссылке с наших веб-сайтов, платформ или через информацию в разделе
          «Свяжитесь с нами» внизу этого Уведомления о конфиденциальности. Вы должны периодически просматривать это
          Уведомление о конфиденциальности, чтобы быть в курсе наших самых актуальных политик и практик.
        </p>
      </div>
      <div id="controller_processor">
        <h1>Подробное уведомление о конфиденциальности</h1>
        <p>
          <strong>Контроллер/Процессор &mdash;</strong> CAI может быть Контроллером данных или Процессором данных для
          целей обработки персональных данных, в зависимости от обстоятельств. Когда CAI обрабатывает данные от имени
          наших клиентов и/или их аффилированных лиц, CAI чаще всего выступает в качестве Процессора данных. Когда CAI
          обрабатывает персональные данные для собственных целей и вне инструкций своих клиентов и/или их аффилированных
          лиц, CAI может быть Контроллером данных. Если у вас есть вопросы о роли CAI в отношении ваших персональных
          данных, пожалуйста, используйте информацию в разделе «Свяжитесь с нами» внизу этого Уведомления о
          конфиденциальности.
        </p>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <p>
          <strong>Кому применяется это уведомление &mdash;</strong> Наше Уведомление о конфиденциальности применяется ко
          всей не связанной с трудоустройством обработке персональных данных CAI независимо от источника сбора. Это
          Уведомление о конфиденциальности применяется к обработке персональных данных, предоставленных: 1) посетителями
          и пользователями различных сайтов, приложений, продуктов и/или услуг CAI; 2) участниками мероприятий CAI и
          отраслевых мероприятий; 3) клиентами и потенциальными клиентами и их представителями; 4) подписчиками на наши
          уведомления, электронные письма и/или рассылки; 5) поставщиками и деловыми партнерами и их представителями; 6)
          посетителями наших офисов; и 7) любыми другими лицами, предоставляющими персональные данные CAI для любых
          других целей.
        </p>
      </div>
      <div id="how_we_collect_personal_data">
        <h2>Как мы собираем персональные данные &mdash;</h2>
        <h3>Персональные данные, которые вы предоставляете:</h3>
        <p>
          CAI собирает персональные данные, которые вы или ваш представитель предоставляете. Это может произойти на
          мероприятии CAI, во время поддержки, через маркетинг, при личном взаимодействии и т. д. Примеры типов
          персональных данных, которые CAI может собирать, включают, но не ограничиваются: ваше имя, адрес, номер
          телефона, адрес электронной почты, работодатель, должность, дата найма, руководитель, стаж, номер
          водительского удостоверения, номер сотрудника, номер социального страхования, другие налоговые
          идентификационные номера, информация о производительности работы и сертификации, платежная информация,
          IP-адрес и/или идентификатор устройства, геолокация, подпись, изображение с камеры, имя пользователя и пароль,
          информация о профсоюзе, этническая принадлежность, некоторая медицинская информация, связанная с работой,
          сообщения и поведенческие данные, а также информация о вас, полученная через размещение файлов cookie и
          пикселей отслеживания во время использования платформы. Если у вас есть вопросы или опасения по поводу любого
          из этих типов персональных данных, пожалуйста, используйте информацию в разделе «Свяжитесь с нами» внизу этого
          Уведомления о конфиденциальности.
        </p>
        <h3>Данные, собранные через наши продукты и услуги:</h3>
        <p>CAI получает персональные данные в связи с предоставлением своих продуктов и услуг, включая:</p>
        <p>(i) Продукты или услуги SaaS, размещенные для клиентов CAI;</p>
        <p>(ii) Веб-решения для совместной коммерции, охватывающие весь процесс заказа и покупки в секторе B2B;</p>
        <p>
          (iii) Программные продукты для локального использования, лицензированные клиенту для использования на их
          собственных площадках через предоставление профессиональных услуг;
        </p>
        <p>
          (iv) Аппаратные продукты для локального использования, проданные клиенту для использования на их собственных
          площадках; и
        </p>
        <p>
          (v) Услуги поддержки клиентов, связанные с SaaS, веб-решениями и локальными программными и аппаратными
          продуктами.
        </p>
        <p>
          Например, когда наши клиенты используют вышеуказанные продукты или услуги, они могут предоставлять данные о
          своих сотрудниках, включая их имена, должности, адреса электронной почты, учетные данные для входа, номера
          телефонов, адреса, даты рождения, номера водительских удостоверений и другую информацию. Некоторые из наших
          веб-решений позволяют клиентам, например, предоставлять персональные данные для создания пользователей
          решения, хранить транзакционные документы, которые могут включать некоторые персональные данные подписантов
          или деловых контактов, использовать геолокацию и хранить контактную информацию, связанную с торговыми
          партнерами. Мы часто обрабатываем персональные данные от имени наших клиентов на основании письменного
          контракта. Мы не контролируем практики обработки данных или защиты данных наших клиентов (которые могут быть
          вашим работодателем, поставщиком услуг или деловым партнером), поэтому их условия могут отличаться от
          изложенных в этом Уведомлении о конфиденциальности. Обратите внимание, что когда CAI собирает персональные
          данные через продукты или услуги, контролируемые нашими клиентами, наши клиенты являются Контроллерами данных
          для того, какие данные собираются и как они используются и раскрываются. В этих случаях CAI действует только
          как Процессор данных. Любые вопросы, связанные с тем, как наши клиенты обрабатывают, используют или делятся
          персональными данными, которые они собирают через наши продукты или услуги, должны быть направлены
          соответствующему клиенту.
        </p>
      </div>
      <div id="automatically_collected_information">
        <h3>Автоматически собранная информация:</h3>
        <p>
          Когда вы перемещаетесь по нашим веб-сайтам и/или продуктам и приложениям SaaS и взаимодействуете с ними, мы
          можем использовать технологии автоматического сбора данных для сбора определенной информации о вашем
          оборудовании, действиях и шаблонах («Информация о действиях пользователя»), включая: (i) детали вашего
          использования, включая данные о трафике, данные о местоположении через технологию геолокации, журналы и другие
          данные о коммуникациях и ресурсы, к которым вы обращаетесь и которые используете; и (ii) информацию о вашем
          устройстве, включая вашу операционную систему, IP-адрес и другие данные для входа с мобильных устройств.
          Некоторая из этой информации может считаться персональными данными в вашей юрисдикции.
        </p>
        <p>
          Информация о действиях пользователя, которую мы собираем, помогает нам улучшать наши веб-сайты и продукты, а
          также предоставлять более качественные и персонализированные услуги, позволяя нам оценивать шаблоны
          использования, отображать информацию, которую вы запрашиваете, в вашем соответствующем часовом поясе, и
          узнавать вас, когда вы возвращаетесь на наш веб-сайт или продукт.
        </p>
        <p>
          Технологии, которые мы используем для этого автоматического сбора данных, могут включать веб-маяки, пиксельные
          теги, скрипты или файлы cookie. Файл cookie — это небольшой файл, размещаемый на жестком диске вашего
          устройства. Мы используем файлы cookie для анализа использования, настройки наших услуг, измерения
          эффективности и повышения доверия и безопасности. Файлы cookie обсуждаются далее ниже.
        </p>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <h2>Как мы используем собранную информацию &ndash;</h2>
        <p>
          CAI использует собранную информацию для эффективной работы, предоставления своих продуктов и услуг клиентам,
          содействия бизнесу между сторонами в отрасли и управления своими отношениями с клиентами. Мы также используем
          собранную информацию для обработки, оценки и ответа на ваши запросы; ответа на запросы и заявки; создания,
          администрирования и общения с вами по поводу вашей учетной записи (включая любые покупки и платежи);
          управления, оценки и улучшения бизнеса CAI (включая разработку новых продуктов и услуг, управление
          коммуникациями, проведение маркетинговых исследований, анализ продуктов/услуг/веб-сайтов CAI и выполнение
          бухгалтерских, аудиторских, расчетных, согласовательных и сборных мероприятий); обеспечения безопасности
          сетевых услуг и ресурсов CAI; и для соблюдения применимых юридических требований.
        </p>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <h2>Как мы раскрываем собранную информацию</h2>
        <p>Мы можем раскрывать некоторые персональные данные, которые мы собираем, следующим образом:</p>
        <ul>
          <li>Нашим аффилированным лицам и/или деловым партнерам, с которыми у нас есть договорные отношения;</li>
          <li>
            Третьим сторонам, таким как подрядчики, поставщики услуг, консультанты и другие агенты («Поставщики услуг»),
            которые оказывают помощь нашему бизнесу. CAI работает с Поставщиками услуг в некоторых случаях по различным
            причинам, включая, например, обработку платежей по кредитным картам, отслеживание рабочего времени, хостинг
            данных и бухгалтерский учет. Поставщики услуг, с которыми мы делимся такими персональными данными, обычно
            связаны обязательствами по конфиденциальности и защите данных, и список таких Поставщиков услуг можно найти
            на нашем веб-сайте (<b>www.commandalkon.com</b>) в разделе Legal (Список субпроцессоров);
          </li>
          <li>Для выполнения целей, для которых вы или ваш работодатель предоставили такие персональные данные;</li>
          <li>
            Покупателю или другому правопреемнику CAI или любого из наших аффилированных лиц в случае приобретения,
            слияния, отчуждения, реструктуризации, реорганизации, ликвидации или другой продажи или передачи части или
            всех активов CAI или любого из наших аффилированных лиц, будь то в рамках продолжающейся деятельности или в
            рамках банкротства, ликвидации или аналогичных процедур, при которых персональные данные являются частью
            передаваемых активов; и
          </li>
          <li>Для любой другой цели, раскрытой нами, когда вы предоставляете персональные данные.</li>
        </ul>
        <p>
          Несмотря на любые другие положения этого Уведомления о конфиденциальности, мы также оставляем за собой право
          доступа, сохранения и раскрытия любой информации, которую мы собираем, если мы обоснованно считаем, что это
          необходимо: (i) для выполнения юридических требований, включая соблюдение любого судебного приказа, закона или
          юридического процесса и ответ на любой запрос правительства или регулирующего органа, включая ответ на запросы
          правоохранительных органов или других государственных чиновников в ответ на проверенный запрос, связанный с
          уголовным расследованием или предполагаемой незаконной деятельностью; (ii) для выполнения или применения наших
          политик и соглашений; (iii) для обнаружения, предотвращения или иного решения вопросов мошенничества,
          безопасности, доверия и безопасности или технических проблем; (iv) для ответа на запросы поддержки
          пользователей; или (v) для защиты прав, собственности, здоровья или безопасности CAI, наших пользователей,
          любых третьих сторон или общественности в целом.
        </p>
        <h2>Дополнительная информация для жителей Калифорнии и других штатов США</h2>
        <p>
          Персональная информация, которую мы собираем, включает информацию в категориях данных, указанных в таблице
          ниже. Эти категории также представляют категории персональной информации, которую мы собирали за последние 12
          месяцев. Обратите внимание, что категории, указанные ниже, определены законодательством штата Калифорния.
          Включение категории в список ниже указывает только на то, что, в зависимости от услуг и продуктов, которые мы
          предоставляем вам и/или вашему работодателю и деловому партнеру, мы можем собирать или раскрывать некоторую
          информацию в этой категории. Это не обязательно означает, что мы собираем или раскрываем всю информацию,
          указанную в конкретной категории, и не означает, что мы собираем все категории информации для всех лиц. Кроме
          того, хотя мы включаем данные, собранные в контексте бизнес-к-бизнесу, которые считаются персональными данными
          в соответствии с определенными законами о конфиденциальности, такими как GDPR ЕС и Великобритании и Закон о
          правах на конфиденциальность Калифорнии, они могут не считаться персональными данными в других юрисдикциях,
          включая другие штаты США. Мы раскрыли информацию в каждой из нижеуказанных категорий нашим аффилированным
          лицам и поставщикам услуг для наших деловых целей в течение последних 12 месяцев.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Категория</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Источники</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Цель обработки</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Идентификаторы, такие как настоящее имя, псевдоним, почтовый адрес, уникальный личный идентификатор,
                  онлайн-идентификатор, IP-адрес, адрес электронной почты, имя учетной записи, номер социального
                  страхования, номер водительского удостоверения или другие аналогичные идентификаторы.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами, записей, которые у нас
                  есть о вас в процессе предоставления услуг или продуктов, или с помощью файлов cookie и аналогичных
                  технологий.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая содействие бизнесу между сторонами в отрасли и управление отношениями с клиентами.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
                <p>
                  Кроме того, эти данные, а также информация о ваших покупательских предпочтениях, полученная от наших
                  деловых партнеров, также используются в маркетинговых целях, включая предложение вам продуктов и
                  услуг, которые могут вас заинтересовать, как через прямую, так и партнерскую рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Информация, которая идентифицирует, относится к, описывает или может быть связана с конкретным лицом,
                  включая, но не ограничиваясь, ваше имя, подпись, номер социального страхования, физические
                  характеристики или описание, адрес, номер телефона, номер водительского удостоверения или
                  государственного удостоверения личности, медицинская информация.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами, записей, которые у нас
                  есть о вас в процессе предоставления услуг или продуктов, или с помощью файлов cookie и аналогичных
                  технологий.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая содействие бизнесу между сторонами в отрасли и управление отношениями с клиентами.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
                <p>
                  Кроме того, эти данные также используются в маркетинговых целях, включая предложение вам продуктов и
                  услуг, которые могут вас заинтересовать, как через прямую, так и партнерскую рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Коммерческая информация, включая записи о личной собственности, продуктах или услугах, приобретенных,
                  полученных или рассмотренных, или другие истории или тенденции покупок или потребления.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами, или записей, которые у
                  нас есть о вас в процессе предоставления услуг или продуктов.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая содействие бизнесу между сторонами в отрасли и управление отношениями с клиентами.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
                <p>
                  Кроме того, эти данные, а также информация о ваших покупательских предпочтениях, полученная от наших
                  деловых партнеров, также используются в маркетинговых целях, включая предложение вам продуктов и
                  услуг, которые могут вас заинтересовать, как через прямую, так и партнерскую рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Информация о действиях в Интернете или другой электронной сети, включая, но не ограничиваясь, историю
                  просмотров, историю поиска и информацию о взаимодействии потребителя с веб-сайтом, приложением или
                  рекламой в Интернете.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами или с помощью файлов
                  cookie и аналогичных технологий.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
                <p>
                  Кроме того, эти данные также используются в маркетинговых целях, включая предложение вам продуктов и
                  услуг, которые могут вас заинтересовать, как через прямую, так и партнерскую рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Данные о геолокации.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами, записей, которые у нас
                  есть о вас в процессе предоставления услуг или продуктов, или с помощью файлов cookie и аналогичных
                  технологий.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая содействие бизнесу между сторонами в отрасли и управление отношениями с клиентами.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
                <p>
                  Кроме того, эти данные, а также информация о ваших покупательских предпочтениях, полученная от наших
                  деловых партнеров, также используются в маркетинговых целях, включая предложение вам и предоставление
                  вам продуктов и услуг, которые могут вас заинтересовать, как через прямую, так и партнерскую рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Аудио, электронная, визуальная, тепловая, обонятельная или аналогичная информация.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами, записей, которые у нас
                  есть о вас в процессе предоставления услуг или продуктов, или с помощью файлов cookie и аналогичных
                  технологий.
                </p>
                <p>
                  Для посетителей эта информация собирается с помощью наших камер видеонаблюдения, а для звонящих - с
                  помощью аудиозаписей.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая запись продажных звонков и других звонков. Для посетителей визуальные данные могут
                  обрабатываться для поддержания безопасности и подотчетности, а также для соблюдения местных законов,
                  включая законы, связанные с охраной здоровья и безопасностью.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Профессиональная или связанная с трудоустройством информация.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами или записей, которые у
                  нас есть о вас в процессе предоставления услуг или продуктов.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая содействие бизнесу между сторонами в отрасли и управление отношениями с клиентами.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, и для управления соблюдением требований.
                </p>
                <p>
                  Кроме того, эти данные также используются в маркетинговых целях, включая предложение вам продуктов и
                  услуг, которые могут вас заинтересовать, как через прямую, так и партнерскую рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Выводы, сделанные на основе любой из вышеуказанных категорий информации для создания профиля о
                  потребителе, отражающего предпочтения, характеристики, психологические тенденции, предрасположенности,
                  поведение, установки, интеллект, способности и склонности потребителя.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами, записей, которые у нас
                  есть о вас в процессе предоставления услуг или продуктов, или с помощью файлов cookie и аналогичных
                  технологий.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая содействие бизнесу между сторонами в отрасли и управление отношениями с клиентами.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
                <p>
                  Кроме того, эти данные также используются в маркетинговых целях, включая предложение вам продуктов и
                  услуг, которые могут вас заинтересовать, как через прямую, так и партнерскую рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Чувствительная персональная информация, включая: любую персональную информацию, которая раскрывает
                  номер социального страхования или другой государственный идентификационный номер; данные для входа в
                  учетную запись, геолокацию; этническое происхождение, членство в профсоюзе; содержание почты,
                  электронной почты или текстовых сообщений, если бизнес не является предполагаемым получателем
                  сообщения; и генетические данные.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эта информация собирается непосредственно от вас и/или вашего работодателя, наших деловых партнеров,
                  наших поставщиков услуг, вашего взаимодействия с нашими услугами и продуктами, записей, которые у нас
                  есть о вас в процессе предоставления услуг или продуктов, или с помощью файлов cookie и аналогичных
                  технологий.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Эти данные обрабатываются в связи с рядом наших операционных функций для предоставления вам продуктов
                  и услуг, включая содействие бизнесу между сторонами в отрасли и управление отношениями с клиентами.
                </p>
                <p>
                  Они также обрабатываются для управления и администрирования вашей учетной записи, а также для
                  обнаружения инцидентов безопасности, защиты от вредоносной, обманной, мошеннической или незаконной
                  деятельности, для управления соблюдением требований, анализа данных и технологического развития наших
                  систем.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          CAI не продает вашу информацию другим лицам, включая для целей Закона о конфиденциальности потребителей
          Калифорнии 2018 года (CCPA) и Закона о правах на конфиденциальность Калифорнии 2020 года (CPRA).
        </p>
      </div>
      <div id="third_party_services">
        <h2>Сторонние сервисы &ndash;</h2>
        <p>
          Вам может быть предоставлена возможность выбрать доступ и использование сторонних сервисов или приложений
          (&ldquo;Сторонние сервисы&rdquo;) через некоторые из наших веб-сайтов и/или продуктов. Это Уведомление о
          конфиденциальности касается только сбора, использования и раскрытия информации, собранной CAI. Оно не
          распространяется на практики третьих лиц, которых мы не владеем, не контролируем, не нанимаем и не управляем,
          включая, но не ограничиваясь, любые Сторонние сервисы. Получая доступ или используя сторонние сервисы или
          приложения через наши веб-сайты и/или продукты, вы направляете нас раскрывать вашу информацию Стороннему
          сервису от вашего имени по запросу этой третьей стороны, и вы соглашаетесь, что использование или раскрытие
          вашей информации третьей стороной будет регулироваться политикой конфиденциальности этой третьей стороны и
          может отличаться от того, как мы используем и раскрываем вашу информацию.
        </p>
      </div>
      <div id="security">
        <h2>Безопасность &ndash;</h2>
        <p>
          CAI использует разумные и соответствующие административные, технические и физические меры безопасности,
          соответствующие типам данных, которые он обрабатывает, для защиты персональных данных от потери, неправильного
          использования и несанкционированного доступа, раскрытия, изменения и/или уничтожения. Хотя мы прилагаем все
          усилия для защиты вашей конфиденциальности, никакие меры безопасности не являются 100% эффективными, и мы не
          можем гарантировать безопасность информации или что ваши персональные данные или частные коммуникации всегда
          останутся конфиденциальными. Несанкционированный доступ или использование, сбой оборудования или программного
          обеспечения и другие факторы могут нарушить безопасность пользовательской информации.
        </p>
        <p>
          Безопасность вашей информации также зависит от вас. Если мы предоставили вам (или если вы выбрали) учетные
          данные для входа (т.е. идентификатор пользователя и пароль) для доступа к определенным частям наших услуг или
          приложений, вы несете ответственность за сохранение конфиденциальности этих учетных данных. Мы просим вас не
          делиться своими учетными данными с кем-либо. Вы несете полную ответственность за защиту учетных данных
          пользователя и за все использование ваших учетных данных, которое находится под вашим контролем.
        </p>
      </div>
      <div id="retention_of_data">
        <h2>Сохранение информации &ndash;</h2>
        <p>
          Мы будем сохранять персональные данные в течение времени, необходимого для выполнения целей, изложенных в этом
          Уведомлении о конфиденциальности. Это сохранение обычно длится на протяжении всего срока отношений плюс
          короткий период времени, установленный в нашем графике сохранения данных. Однако мы можем сохранять
          определенную информацию дольше, как того требует применимое законодательство (например, налоговые,
          бухгалтерские или другие юридические требования) или для законных деловых целей. Как только нам больше не
          нужно использовать ваши персональные данные для выполнения наших обязательств, мы удалим их из наших систем и
          записей и/или предпримем шаги для их надлежащей анонимизации, чтобы вас больше нельзя было идентифицировать по
          ним. Каждое из использований, изложенных в этом Уведомлении о конфиденциальности, представляет собой законный
          интерес CAI в обработке или использовании собранных персональных данных или необходимо для выполнения
          контракта. Если вы не согласны с этим подходом, вы можете возразить против обработки или использования ваших
          персональных данных CAI, связавшись с CAI в письменной форме через методы, указанные ниже в разделе
          &ldquo;Свяжитесь с нами&rdquo; внизу этого Уведомления о конфиденциальности.
        </p>
      </div>
      <div id="your_rights_and_choices">
        <h2>Ваши права и выборы </h2>
        <h3>Информация об учетной записи и запросы:</h3>
        <p>
          В соответствии с применимым законодательством в зависимости от юрисдикции (например, для EEA с 25 мая 2018
          года или для штата Калифорния с 1 января 2020 года), у вас могут быть следующие права, указанные ниже, в
          отношении ваших персональных данных:
        </p>
        <p>
          1) право на доступ (это означает, что вы можете запросить у нас копию ваших персональных данных, которые мы
          собрали о вас, категории источников, из которых была собрана информация, цели сбора данных, категории третьих
          сторон, с которыми мы поделились данными, и категории персональных данных, которые были переданы третьим
          сторонам для деловых целей);
        </p>
        <p>
          2) право на исправление (это означает, что вы можете уведомить нас через раздел &ldquo;Свяжитесь с нами&rdquo;
          внизу этого Уведомления о конфиденциальности, чтобы исправить любые ошибки в ваших персональных данных или
          обновить ваши предпочтения; мы также можем не удовлетворить ваш запрос, если считаем, что это нарушит
          законодательство или юридические требования или сделает информацию неверной; данные, сохраняемые исключительно
          для резервного копирования, обычно исключаются);
        </p>
        <p>
          3) право на передачу (это означает, что вы можете запросить у нас копию ваших персональных данных для передачи
          третьей стороне по вашему выбору);
        </p>
        <p>
          4) право на ограничение (если наша обработка ваших персональных данных необходима для наших законных
          интересов, в зависимости от применимого законодательства о защите данных/конфиденциальности, вы можете
          возразить против этой обработки в любое время, с учетом некоторых ограничений и исключений; вы также можете
          иметь возможность ограничить определенные типы обработки ваших персональных данных при определенных
          обстоятельствах; в частности, вы можете запросить ограничение использования ваших персональных данных, если вы
          оспариваете их точность, если обработка ваших персональных данных признана незаконной, или если нам больше не
          нужны ваши персональные данные для обработки, но мы сохранили их, как это разрешено законом);
        </p>
        <p>
          5) право на отзыв согласия (это означает, что в той мере, в какой наша обработка ваших персональных данных
          основана на вашем согласии, вы можете отозвать свое согласие в любое время; отзыв вашего согласия не повлияет
          на законность обработки, основанной на вашем согласии до его отзыва, и не повлияет на законность нашей
          продолжающейся обработки, основанной на любом другом законном основании для обработки ваших персональных
          данных);
        </p>
        <p>
          6) право на удаление (это означает, что вы можете попросить нас удалить ваши персональные данные из наших
          систем, и мы это сделаем, если только нам не потребуется сохранить такую информацию для предоставления услуг
          вам или если нам потребуются такие персональные данные для выполнения наших юридических или деловых
          обязательств в соответствии с применимым законодательством); или
        </p>
        <p>
          7) право сказать &ldquo;нет&rdquo; продаже ваших персональных данных (Примечание: CAI не продает персональные
          данные).
        </p>
        <p>
          Вы можете воспользоваться некоторыми или всеми этими правами, войдя в свою учетную запись в продуктах, которые
          вы используете. В противном случае, чтобы запросить такую информацию напрямую, пожалуйста, отправьте
          письменный запрос, используя данные, указанные в разделе &#8220;Свяжитесь с нами&#8221; внизу этого
          Уведомления о конфиденциальности.
        </p>
        <p>
          Обратите внимание, что в соответствии с законодательством Калифорнии мы обязаны отвечать на запросы о доступе
          к персональной информации от одного и того же потребителя не более двух раз в течение 12-месячного периода. В
          соответствии с законодательством ЕС и Калифорнии, если физическое лицо делает необоснованные, повторяющиеся
          или чрезмерные запросы (по нашему разумному усмотрению) на доступ к персональным данным, мы можем взимать
          плату, подлежащую максимальному размеру, установленному законом.
        </p>
        <p>
          Помните, что в некоторых регионах у вас также есть право подать жалобу в орган по защите данных
          (&ldquo;DPA&rdquo;) о нашем сборе и использовании ваших персональных данных. Для получения дополнительной
          информации, пожалуйста, свяжитесь с вашим местным DPA.
        </p>
        <p>
          В случае, если CAI обрабатывает персональные данные о вас от имени клиента, пожалуйста, направляйте свои
          запросы на конфиденциальность и запросы на доступ, исправление или удаление персональных данных этому клиенту.
        </p>
        <p>
          Перед предоставлением информации, которую вы запрашиваете в соответствии с некоторыми из этих прав, мы должны
          быть в состоянии подтвердить вашу личность. Для подтверждения вашей личности вам может потребоваться
          предоставить информацию о себе, включая, в зависимости от применимости, ответы на вопросы безопасности, ваше
          имя, номер государственного удостоверения личности, дату рождения, контактную информацию или другую
          идентифицирующую информацию. Мы сопоставим эту информацию с информацией, которую мы ранее собрали о вас, чтобы
          подтвердить вашу личность и ваш запрос. Если мы не сможем подтвердить вашу личность в рамках вашего запроса,
          мы не сможем удовлетворить ваш запрос. Мы не обязаны собирать дополнительную информацию, чтобы вы могли
          подтвердить свою личность. Для запросов на удаление вам потребуется подать проверяемый запрос на удаление.
        </p>
        <p>
          Если вы хотите назначить уполномоченного агента для подачи запроса от вашего имени, вы должны предоставить
          агенту письменное, подписанное разрешение на подачу запросов на права конфиденциальности от вашего имени или
          предоставить письмо от вашего адвоката. Агент или адвокат должны предоставить это разрешение во время подачи
          запроса. Мы можем потребовать, чтобы вы подтвердили свою личность непосредственно с нами, прежде чем мы
          предоставим любую запрашиваемую информацию вашему утвержденному агенту.
        </p>
        <p>Информация, собранная для целей проверки вашего запроса, будет использоваться только для проверки.</p>
        <p>
          Если вы решите воспользоваться любым из этих прав, в той мере, в какой они применимы, законодательство штатов
          США запрещает нам дискриминировать вас на основании выбора воспользоваться вашими правами на
          конфиденциальность. Однако мы можем взимать другую ставку или предоставлять другой уровень обслуживания в той
          мере, в какой это разрешено законом.
        </p>
        <h3>Сигналы &ldquo;Не отслеживать&rdquo;:</h3>
        <p>
          Некоторые веб-браузеры позволяют вам передавать сигнал на веб-сайты и онлайн-сервисы, указывая предпочтение,
          чтобы они &ldquo;не отслеживали&rdquo; вашу онлайн-активность. В настоящее время мы не учитываем такие сигналы
          и не изменяем, какую информацию мы собираем или как мы используем эту информацию, в зависимости от того,
          передается ли такой сигнал или получен нами.
        </p>
        <h3>Промоционные коммуникации:</h3>
        <p>
          Время от времени мы можем отправлять вам маркетинговые сообщения о наших продуктах в соответствии с вашими
          предпочтениями. Вы можете отказаться от получения промоционных сообщений от нас в любое время, следуя
          инструкциям в этих сообщениях (часто это будет уведомление или ссылка внизу сообщения). Если вы откажетесь, мы
          все равно можем отправлять вам непро моционные сообщения, такие как те, которые касаются вашей учетной записи
          или наших текущих деловых отношений. Запросы на отказ от промоционных сообщений также могут быть отправлены в
          CAI в письменной форме через методы, указанные ниже в разделе &ldquo;Свяжитесь с нами&rdquo; внизу этого
          Уведомления о конфиденциальности.
        </p>
        <h3>Файлы cookie:</h3>
        <p>
          Вы можете управлять нашим использованием файлов cookie через баннер файлов cookie, который появляется при
          доступе к нашим сайтам. Если вы предпочитаете, вы обычно можете настроить свой браузер на удаление или
          отклонение файлов cookie браузера или очистку локального хранилища. Вы также можете сделать любые запросы на
          управление файлами cookie, связавшись с нами в письменной форме через методы, указанные ниже в разделе
          &ldquo;Свяжитесь с нами&rdquo; внизу этого Уведомления о конфиденциальности. Обратите внимание, что если вы
          решите удалить или отклонить некоторые файлы cookie, это может повлиять на функциональность нашего веб-сайта и
          услуг. У нас есть независимая Политика использования файлов cookie, доступная на нашем веб-сайте (
          <b>www.commandalkon.com</b>) в разделе Legal, которая предоставляет более подробную информацию.
        </p>
        <h3>Информация о местоположении:</h3>
        <p>
          Вы можете включать и отключать услуги, основанные на местоположении, настроив параметры своего мобильного
          устройства. Обратите внимание, что если вы решите отключить услуги, основанные на местоположении, это может
          повлиять на полную функциональность наших продуктов и услуг.
        </p>
        <h3>Права на конфиденциальность в Калифорнии:</h3>
        <p>
          Закон Калифорнии позволяет пользователям, которые являются жителями Калифорнии, запрашивать и получать от нас
          один раз в год, бесплатно, список третьих сторон, которым мы раскрыли их персональные данные (если таковые
          имеются) для их прямых маркетинговых целей в предыдущем календарном году, а также тип персональных данных,
          раскрытых этим сторонам. За исключением случаев, предусмотренных в этом Уведомлении о конфиденциальности или
          по соглашению с субъектом данных, CAI не продает персональные данные третьим сторонам для их собственных
          маркетинговых целей.
        </p>
      </div>
      <div id="third_party_services">
        <h2>Сторонние сервисы &ndash;</h2>
        <p>
          Вам может быть предоставлена возможность выбрать доступ и использование сторонних сервисов или приложений
          (&ldquo;Сторонние сервисы&rdquo;) через некоторые из наших веб-сайтов и/или продуктов. Это Уведомление о
          конфиденциальности касается только сбора, использования и раскрытия информации, собранной CAI. Оно не
          распространяется на практики третьих лиц, которых мы не владеем, не контролируем, не нанимаем и не управляем,
          включая, но не ограничиваясь, любые Сторонние сервисы. Получая доступ или используя сторонние сервисы или
          приложения через наши веб-сайты и/или продукты, вы направляете нас раскрывать вашу информацию Стороннему
          сервису от вашего имени по запросу этой третьей стороны, и вы соглашаетесь, что использование или раскрытие
          вашей информации третьей стороной будет регулироваться политикой конфиденциальности этой третьей стороны и
          может отличаться от того, как мы используем и раскрываем вашу информацию.
        </p>
      </div>
      <div id="security">
        <h2>Безопасность &ndash;</h2>
        <p>
          CAI использует разумные и соответствующие административные, технические и физические меры безопасности,
          соответствующие типам данных, которые он обрабатывает, для защиты персональных данных от потери, неправильного
          использования и несанкционированного доступа, раскрытия, изменения и/или уничтожения. Хотя мы прилагаем все
          усилия для защиты вашей конфиденциальности, никакие меры безопасности не являются 100% эффективными, и мы не
          можем гарантировать безопасность информации или что ваши персональные данные или частные коммуникации всегда
          останутся конфиденциальными. Несанкционированный доступ или использование, сбой оборудования или программного
          обеспечения и другие факторы могут нарушить безопасность пользовательской информации.
        </p>
        <p>
          Безопасность вашей информации также зависит от вас. Если мы предоставили вам (или если вы выбрали) учетные
          данные для входа (т.е. идентификатор пользователя и пароль) для доступа к определенным частям наших услуг или
          приложений, вы несете ответственность за сохранение конфиденциальности этих учетных данных. Мы просим вас не
          делиться своими учетными данными с кем-либо. Вы несете полную ответственность за защиту учетных данных
          пользователя и за все использование ваших учетных данных, которое находится под вашим контролем.
        </p>
      </div>
      <div id="retention_of_data">
        <h2>Сохранение информации &ndash;</h2>
        <p>
          Мы будем сохранять персональные данные в течение времени, необходимого для выполнения целей, изложенных в этом
          Уведомлении о конфиденциальности. Это сохранение обычно длится на протяжении всего срока отношений плюс
          короткий период времени, установленный в нашем графике сохранения данных. Однако мы можем сохранять
          определенную информацию дольше, как того требует применимое законодательство (например, налоговые,
          бухгалтерские или другие юридические требования) или для законных деловых целей. Как только нам больше не
          нужно использовать ваши персональные данные для выполнения наших обязательств, мы удалим их из наших систем и
          записей и/или предпримем шаги для их надлежащей анонимизации, чтобы вас больше нельзя было идентифицировать по
          ним. Каждое из использований, изложенных в этом Уведомлении о конфиденциальности, представляет собой законный
          интерес CAI в обработке или использовании собранных персональных данных или необходимо для выполнения
          контракта. Если вы не согласны с этим подходом, вы можете возразить против обработки или использования ваших
          персональных данных CAI, связавшись с CAI в письменной форме через методы, указанные ниже в разделе
          &ldquo;Свяжитесь с нами&rdquo; внизу этого Уведомления о конфиденциальности.
        </p>
      </div>
      <div id="your_rights_and_choices">
        <h2>Ваши права и выборы </h2>
        <h3>Информация об учетной записи и запросы:</h3>
        <p>
          В соответствии с применимым законодательством в зависимости от юрисдикции (например, для EEA с 25 мая 2018
          года или для штата Калифорния с 1 января 2020 года), у вас могут быть следующие права, указанные ниже, в
          отношении ваших персональных данных:
        </p>
        <p>
          1) право на доступ (это означает, что вы можете запросить у нас копию ваших персональных данных, которые мы
          собрали о вас, категории источников, из которых была собрана информация, цели сбора данных, категории третьих
          сторон, с которыми мы поделились данными, и категории персональных данных, которые были переданы третьим
          сторонам для деловых целей);
        </p>
        <p>
          2) право на исправление (это означает, что вы можете уведомить нас через раздел &ldquo;Свяжитесь с нами&rdquo;
          внизу этого Уведомления о конфиденциальности, чтобы исправить любые ошибки в ваших персональных данных или
          обновить ваши предпочтения; мы также можем не удовлетворить ваш запрос, если считаем, что это нарушит
          законодательство или юридические требования или сделает информацию неверной; данные, сохраняемые исключительно
          для резервного копирования, обычно исключаются);
        </p>
        <p>
          3) право на передачу (это означает, что вы можете запросить у нас копию ваших персональных данных для передачи
          третьей стороне по вашему выбору);
        </p>
        <p>
          4) право на ограничение (если наша обработка ваших персональных данных необходима для наших законных
          интересов, в зависимости от применимого законодательства о защите данных/конфиденциальности, вы можете
          возразить против этой обработки в любое время, с учетом некоторых ограничений и исключений; вы также можете
          иметь возможность ограничить определенные типы обработки ваших персональных данных при определенных
          обстоятельствах; в частности, вы можете запросить ограничение использования ваших персональных данных, если вы
          оспариваете их точность, если обработка ваших персональных данных признана незаконной, или если нам больше не
          нужны ваши персональные данные для обработки, но мы сохранили их, как это разрешено законом);
        </p>
        <p>
          5) право на отзыв согласия (это означает, что в той мере, в какой наша обработка ваших персональных данных
          основана на вашем согласии, вы можете отозвать свое согласие в любое время; отзыв вашего согласия не повлияет
          на законность обработки, основанной на вашем согласии до его отзыва, и не повлияет на законность нашей
          продолжающейся обработки, основанной на любом другом законном основании для обработки ваших персональных
          данных);
        </p>
        <p>
          6) право на удаление (это означает, что вы можете попросить нас удалить ваши персональные данные из наших
          систем, и мы это сделаем, если только нам не потребуется сохранить такую информацию для предоставления услуг
          вам или если нам потребуются такие персональные данные для выполнения наших юридических или деловых
          обязательств в соответствии с применимым законодательством); или
        </p>
        <p>
          7) право сказать &ldquo;нет&rdquo; продаже ваших персональных данных (Примечание: CAI не продает персональные
          данные).
        </p>
        <p>
          Вы можете воспользоваться некоторыми или всеми этими правами, войдя в свою учетную запись в продуктах, которые
          вы используете. В противном случае, чтобы запросить такую информацию напрямую, пожалуйста, отправьте
          письменный запрос, используя данные, указанные в разделе &#8220;Свяжитесь с нами&#8221; внизу этого
          Уведомления о конфиденциальности.
        </p>
        <p>
          Обратите внимание, что в соответствии с законодательством Калифорнии мы обязаны отвечать на запросы о доступе
          к персональной информации от одного и того же потребителя не более двух раз в течение 12-месячного периода. В
          соответствии с законодательством ЕС и Калифорнии, если физическое лицо делает необоснованные, повторяющиеся
          или чрезмерные запросы (по нашему разумному усмотрению) на доступ к персональным данным, мы можем взимать
          плату, подлежащую максимальному размеру, установленному законом.
        </p>
        <p>
          Помните, что в некоторых регионах у вас также есть право подать жалобу в орган по защите данных
          (&ldquo;DPA&rdquo;) о нашем сборе и использовании ваших персональных данных. Для получения дополнительной
          информации, пожалуйста, свяжитесь с вашим местным DPA.
        </p>
        <p>
          В случае, если CAI обрабатывает персональные данные о вас от имени клиента, пожалуйста, направляйте свои
          запросы на конфиденциальность и запросы на доступ, исправление или удаление персональных данных этому клиенту.
        </p>
        <p>
          Перед предоставлением информации, которую вы запрашиваете в соответствии с некоторыми из этих прав, мы должны
          быть в состоянии подтвердить вашу личность. Для подтверждения вашей личности вам может потребоваться
          предоставить информацию о себе, включая, в зависимости от применимости, ответы на вопросы безопасности, ваше
          имя, номер государственного удостоверения личности, дату рождения, контактную информацию или другую
          идентифицирующую информацию. Мы сопоставим эту информацию с информацией, которую мы ранее собрали о вас, чтобы
          подтвердить вашу личность и ваш запрос. Если мы не сможем подтвердить вашу личность в рамках вашего запроса,
          мы не сможем удовлетворить ваш запрос. Мы не обязаны собирать дополнительную информацию, чтобы вы могли
          подтвердить свою личность. Для запросов на удаление вам потребуется подать проверяемый запрос на удаление.
        </p>
        <p>
          Если вы хотите назначить уполномоченного агента для подачи запроса от вашего имени, вы должны предоставить
          агенту письменное, подписанное разрешение на подачу запросов на права конфиденциальности от вашего имени или
          предоставить письмо от вашего адвоката. Агент или адвокат должны предоставить это разрешение во время подачи
          запроса. Мы можем потребовать, чтобы вы подтвердили свою личность непосредственно с нами, прежде чем мы
          предоставим любую запрашиваемую информацию вашему утвержденному агенту.
        </p>
        <p>Информация, собранная для целей проверки вашего запроса, будет использоваться только для проверки.</p>
        <p>
          Если вы решите воспользоваться любым из этих прав, в той мере, в какой они применимы, законодательство штатов
          США запрещает нам дискриминировать вас на основании выбора воспользоваться вашими правами на
          конфиденциальность. Однако мы можем взимать другую ставку или предоставлять другой уровень обслуживания в той
          мере, в какой это разрешено законом.
        </p>
        <h3>Сигналы &ldquo;Не отслеживать&rdquo;:</h3>
        <p>
          Некоторые веб-браузеры позволяют вам передавать сигнал на веб-сайты и онлайн-сервисы, указывая предпочтение,
          чтобы они &ldquo;не отслеживали&rdquo; вашу онлайн-активность. В настоящее время мы не учитываем такие сигналы
          и не изменяем, какую информацию мы собираем или как мы используем эту информацию, в зависимости от того,
          передается ли такой сигнал или получен нами.
        </p>
        <h3>Промоционные коммуникации:</h3>
        <p>
          Время от времени мы можем отправлять вам маркетинговые сообщения о наших продуктах в соответствии с вашими
          предпочтениями. Вы можете отказаться от получения промоционных сообщений от нас в любое время, следуя
          инструкциям в этих сообщениях (часто это будет уведомление или ссылка внизу сообщения). Если вы откажетесь, мы
          все равно можем отправлять вам непро моционные сообщения, такие как те, которые касаются вашей учетной записи
          или наших текущих деловых отношений. Запросы на отказ от промоционных сообщений также могут быть отправлены в
          CAI в письменной форме через методы, указанные ниже в разделе &ldquo;Свяжитесь с нами&rdquo; внизу этого
          Уведомления о конфиденциальности.
        </p>
        <h3>Файлы cookie:</h3>
        <p>
          Вы можете управлять нашим использованием файлов cookie через баннер файлов cookie, который появляется при
          доступе к нашим сайтам. Если вы предпочитаете, вы обычно можете настроить свой браузер на удаление или
          отклонение файлов cookie браузера или очистку локального хранилища. Вы также можете сделать любые запросы на
          управление файлами cookie, связавшись с нами в письменной форме через методы, указанные ниже в разделе
          &ldquo;Свяжитесь с нами&rdquo; внизу этого Уведомления о конфиденциальности. Обратите внимание, что если вы
          решите удалить или отклонить некоторые файлы cookie, это может повлиять на функциональность нашего веб-сайта и
          услуг. У нас есть независимая Политика использования файлов cookie, доступная на нашем веб-сайте (
          <b>www.commandalkon.com</b>) в разделе Legal, которая предоставляет более подробную информацию.
        </p>
        <h3>Информация о местоположении:</h3>
        <p>
          Вы можете включать и отключать услуги, основанные на местоположении, настроив параметры своего мобильного
          устройства. Обратите внимание, что если вы решите отключить услуги, основанные на местоположении, это может
          повлиять на полную функциональность наших продуктов и услуг.
        </p>
        <h3>Права на конфиденциальность в Калифорнии:</h3>
        <p>
          Закон Калифорнии позволяет пользователям, которые являются жителями Калифорнии, запрашивать и получать от нас
          один раз в год, бесплатно, список третьих сторон, которым мы раскрыли их персональные данные (если таковые
          имеются) для их прямых маркетинговых целей в предыдущем календарном году, а также тип персональных данных,
          раскрытых этим сторонам. За исключением случаев, предусмотренных в этом Уведомлении о конфиденциальности или
          по соглашению с субъектом данных, CAI не продает персональные данные третьим сторонам для их собственных
          маркетинговых целей.
        </p>
      </div>
      <div>
        <h2>Жалобы &ndash;</h2>
        <p>
          Без ущерба для любых других административных или судебных средств правовой защиты, которые могут у вас быть,
          вы имеете право подать жалобу в местные государственные регулирующие органы/органы по защите данных, если вы
          считаете, что мы нарушили применимые требования конфиденциальности или защиты данных при обработке
          персональных данных о вас.
        </p>
        <h3>В Великобритании вы можете связаться с:</h3>
        <p>Управление уполномоченного по информации</p>
        <p>
          Water Lane, Wycliffe House
          <br />
          Wilmslow &#8211; Cheshire SK9 5AF
        </p>
        <p>Тел. +44 1625 545 700</p>
        <p>
          Электронная почта: <b>casework@ico.org.uk</b>
        </p>
        <p>
          Веб-сайт: <b>https://ico.org.uk</b>
        </p>
        <h3>Для ЕС, пожалуйста, см.:</h3>
        <p>
          <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
        </p>
        <h2>Изменения в уведомлении о конфиденциальности &ndash;</h2>
        <p>
          Мы можем вносить изменения в это Уведомление о конфиденциальности в любое время, размещая измененное
          Уведомление о конфиденциальности на наших веб-сайтах. Дата последнего изменения Уведомления о
          конфиденциальности указана в верхней части этого Уведомления о конфиденциальности. Все измененные условия
          автоматически вступают в силу после их первоначального размещения на нашем веб-сайте, если только изменение
          любого применимого закона не требует немедленного внесения изменений. Вы несете ответственность за
          периодическую проверку этого Уведомления о конфиденциальности на предмет любых изменений.
        </p>
      </div>
      <div id="contact_us">
        <h2>Свяжитесь с нами </h2>
        <p>
          Чтобы связаться с CAI по вопросам или проблемам, связанным с этим Уведомлением о конфиденциальности или
          практиками CAI в отношении персональных данных, пожалуйста, используйте контактную информацию ниже. Если вы
          являетесь резидентом EEA, пожалуйста, обратите внимание, что когда CAI действует в качестве контроллера
          данных, ответственного за ваши персональные данные, юридическим лицом является Command Alkon Incorporated в
          Соединенных Штатах. CAI рассмотрит все вопросы и жалобы, связанные с этим Уведомлением о конфиденциальности, в
          разумные сроки.
        </p>
        <p>
          <strong>Электронная почта:</strong>
        </p>
        <p>
          <b>privacy@commandalkon.com</b>
        </p>
        <p>
          <strong>Или напишите по адресу:</strong>
        </p>
        <p>Command Alkon Incorporated</p>
        <p>Главный специалист по конфиденциальности, c/o Юридический отдел</p>
        <p>
          6750 Crosby Court
          <br />
          Дублин, Огайо 43016
        </p>
        <p>
          <strong>Или позвоните:</strong>
        </p>
        <p>1-800-624-1872 (бесплатный номер в США)</p>
        <p>0-800-022-9682 (бесплатный международный номер)</p>
        <p>
          <strong>Веб-сайт:</strong>
        </p>
        <p>
          <b>www.commandalkon.com</b>
        </p>
      </div>
    </Styled>
  );
};
