import { Core, Drawer, Form, Localization, Responsive } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { CustomListComponent } from '../custom-list-component/CustomListComponent';
import { ListSectionDesktop } from '../custom-list-component/desktop/ListSectionDesktop';
import { ListSectionMobile } from '../custom-list-component/mobile/ListSectionMobile';
import { FormSection } from './FormSection';
import {
  useCompanySetup,
  useFormsAndWaiversSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import { activeFormsAndWaiversArray } from '../../../getActiveForms';

const Styled = styled.div`
  flex: 1;
  overflow: visible;
  .list {
    overflow: visible;
    .list-section {
      overflow: visible;
      .list-component {
        overflow: visible;
      }
    }
  }
`;

const paths = {
  description: 'waterProduct.name',
  driverDidNotAdd: 'driverDidNotAdd',
  quantity: 'quantity',
  isReasonFieldEnabled: 'waterProduct.mobileTicket.isReasonFieldEnabled',
  isConcreteOnTruckFieldEnabled: 'waterProduct.mobileTicket.isConcreteOnTruckFieldEnabled',
  isTimeFieldEnabled: 'waterProduct.mobileTicket.isTimeFieldEnabled',
  concreteOnTruck: 'concreteOnTruck',
  time: 'time',
  reason: 'reason.description',
  errors: 'waterAddedEvents',
};

export const View = ({ renderWaterReleaseModal, disableWaterReleaseButton, handleWaterReleaseReset }) => {
  const {
    fieldConfig,
    resetMode,
    parentFormContext: { isValid, values },
  } = Form.useFormContext();
  const { ticket } = useTicketContext();
  const { openDrawer } = Drawer.useDrawerContext();
  const companySetup = useCompanySetup();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();
  const { translateObjects } = Localization.useTranslateObjects();
  const { userLocale } = Localization.useLocalizationContext();

  const handleClick = React.useCallback(
    isMobile => () => {
      openDrawer({
        titleStringId: 'addWater',
        component: <FormSection isMobile={isMobile} />,
        onClose: resetMode,
        width: '100%',
      });
    },
    [openDrawer, resetMode]
  );

  const openWaterRelease = React.useCallback(() => {
    openDrawer({
      titleStringId: 'waterRelease',
      component: renderWaterReleaseModal(),
      onClose: handleWaterReleaseReset,
      width: '100%',
    });
  }, [openDrawer, renderWaterReleaseModal, handleWaterReleaseReset]);

  const hasItems = React.useMemo(() => {
    return !!get(values, fieldConfig.path)?.length;
  }, [fieldConfig.path, values]);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'WATER',
        formsAndWaiversSetup,
        vehicleSetup,
        vehicleTypeSetup,
        companySetup,
        translateObjects,
        userLocale,
      }),
    [ticket, formsAndWaiversSetup, vehicleSetup, vehicleTypeSetup, companySetup, translateObjects, userLocale]
  );

  const hideButtonWaterReleaseSignature = React.useMemo(
    () => !companySetup?.data?.isWaterReleaseFormEnabled || activeFormsAndWaivers?.length === 0,
    [companySetup, activeFormsAndWaivers]
  );

  const buttonWaterReleaseSignature = React.useMemo(() => {
    return (
      <Core.Button
        type="primary"
        stringId="waterRelease"
        onClick={openWaterRelease}
        data-testid="waterRelease-button"
        disabled={disableWaterReleaseButton}
        hidden={hideButtonWaterReleaseSignature}
      />
    );
  }, [openWaterRelease, disableWaterReleaseButton, hideButtonWaterReleaseSignature]);

  return hasItems ? (
    <Styled>
      <Responsive>
        <ListSectionMobile
          requirementFulfilled={isValid}
          CustomListComponent={CustomListComponent}
          paths={paths}
          onClick={handleClick(true)}
          autoEdit
          buttonWaterReleaseSignature={buttonWaterReleaseSignature}
        />
        <ListSectionDesktop
          requirementFulfilled={isValid}
          CustomListComponent={CustomListComponent}
          paths={paths}
          onClick={handleClick(false)}
          autoEdit
          buttonWaterReleaseSignature={buttonWaterReleaseSignature}
        />
      </Responsive>
    </Styled>
  ) : (
    <Responsive>
      <div className="water-buttons-mobile" style={{ marginTop: hideButtonWaterReleaseSignature ? '' : '15px' }}>
        {buttonWaterReleaseSignature}
        <FormSection isMobile={true} />
      </div>
      <div className="water-buttons-desktop">
        {buttonWaterReleaseSignature}
        <FormSection isMobile={false} />
      </div>
    </Responsive>
  );
};
