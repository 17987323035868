import { Responsive } from 'connex-cds';
import UnloadingPointDesktop from './UnloadingPointDesktop';
import { useTicketContext } from '../../../TicketContext';
import React from 'react';
import { find } from 'lodash';
import UnloadingPointMobile from './UnloadingPointMobile';

export const UnloadingPoint = () => {
  const { ticket } = useTicketContext();

  const deliveryAddress = React.useMemo(() => {
    const address = ticket?.destination?.address;
    return [address?.address1, address?.address2, address?.city, address?.countrySubDivision, address?.postalCode]
      ?.filter(Boolean)
      ?.join(' ');
  }, [ticket]);

  const siteContact = React.useMemo(() => {
    const contact = find(ticket?.destination?.contacts, { contactRole: 'general' });
    return { phone: contact?.phone || '', name: contact?.name };
  }, [ticket?.destination?.contacts]);

  return (
    <Responsive>
      <UnloadingPointMobile deliveryAddress={deliveryAddress} siteContact={siteContact} ticket={ticket} />
      <UnloadingPointDesktop deliveryAddress={deliveryAddress} siteContact={siteContact} ticket={ticket} />
    </Responsive>
  );
};
