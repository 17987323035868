import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Drawer, Form, Layout, Responsive } from 'connex-cds';
import { CommentsMobile } from './CommentsMobile';
import { CommentsDesktop } from './CommentsDesktop';
import { useTicketContext } from '../../../../TicketContext';
import {
  useCompanySetup,
  useMaterialComplianceSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from '../../../../MasterDataProvider';
import { CommentEditor } from './CommentEditor';
import { useViewport } from '../../../../../../../../util/useViewport';
import { find } from 'lodash';

const Styled = styled(Layout.Column)`
  ${style}
`;

const filterCompliance = type => compliance => compliance?.classType === type;

export const Comments = () => {
  const { values } = Form.useFormContext();
  const { finalized } = useTicketContext();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { ticket } = useTicketContext();

  const dimensions = useViewport();

  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();
  const materialComplianceSetup = useMaterialComplianceSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return find(vehicleTypeSetup.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const materialComplianceEnabled = React.useMemo(() => {
    if (!companySetup?.data?.isMaterialComplianceEnabled) {
      return false;
    } else if (vehicleTypeData) {
      if (
        companySetup?.data?.isMaterialComplianceEnabled !== false &&
        vehicleTypeData?.isMaterialComplianceEnabled !== true
      ) {
        return false;
      }
    }
    return true;
  }, [companySetup?.data?.isMaterialComplianceEnabled, vehicleTypeData]);

  const activeMaterialComplianceSetup = React.useMemo(() => {
    if (materialComplianceSetup?.isSuccess && materialComplianceSetup?.data?.length) {
      return materialComplianceSetup?.data?.filter?.(compliance => compliance?.status === 'ACTIVE');
    }

    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const air = React.useMemo(
    () =>
      [
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('air')),
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('AIR')),
      ]?.[0],
    [activeMaterialComplianceSetup]
  );

  const consistence = React.useMemo(
    () =>
      [
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('consistence')),
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('CONSISTENCE')),
      ]?.[0],
    [activeMaterialComplianceSetup]
  );

  const handleCommentClick = React.useCallback(() => {
    if (!finalized || values?.customerStatus === 'ACCEPTED' || values?.customerStatus === 'REJECTED') return;

    openDrawer({
      titleStringId: 'customerComment',
      component: <CommentEditor onFinally={closeDrawer} />,
      width: Math.min(750, dimensions?.width),
    });
  }, [closeDrawer, dimensions?.width, finalized, openDrawer, values?.customerStatus]);

  return (
    <Styled className={cn('comments')}>
      <Responsive>
        <CommentsMobile
          companySetup={companySetup}
          materialComplianceEnabled={materialComplianceEnabled}
          air={air}
          consistence={consistence}
          handleCommentClick={handleCommentClick}
        />
        <CommentsDesktop
          companySetup={companySetup}
          materialComplianceEnabled={materialComplianceEnabled}
          air={air}
          consistence={consistence}
          handleCommentClick={handleCommentClick}
        />
      </Responsive>
    </Styled>
  );
};
