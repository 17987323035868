import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Мобильный билет',
  ...features,
  save: 'Сохранить',
  username: 'Имя пользователя',
  password: 'Пароль',
  login: 'Войти',
  required: 'Обязательно',
  light: 'Светлый',
  dark: 'Темный',
  rememberMe: 'Запомнить меня',
  eula: 'Лицензионное соглашение',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Политика конфиденциальности',
  customer: 'Клиент',
  project: 'Проект',
  isBillable: 'Платный',
  dispatchDateTime: 'Дата',
  orderId: 'Заказ',
  ticketId: 'Билет',
  location: 'Местоположение',
  vehicleTypeName: 'Транспортное средство',
  product: 'Продукт',
  products: 'Продукты',
  salesTax: 'Налог с продаж',
  totalPrice: 'Итого',
  extendedPrice: 'Расширенная цена',
  destination: 'Назначение',
  productId: 'ID продукта',
  statusCode: 'Статус',
  vehicleTypeId: 'ID типа транспортного средства',
  locationId: 'ID местоположения',
  customerId: 'ID клиента',
  projectId: 'ID проекта',
  dispatchTime: 'Время',
  showAll: 'Показать все',
  hideAll: 'Скрыть все',
  undoChanges: 'Отменить изменения',
  resetColumns: 'Сбросить столбцы',
  displayRowCount: `Отображение {count, plural, 
    =0 {0 строк} 
    one {# строка} 
    other {# строк} 
  }`,
  columnFilters: 'Фильтры столбцов',
  visible: 'Видимый',
  hidden: 'Скрытый',
  itemDetail: 'Детали элемента',
  quantity: 'Количество',
  quantityMaxWaterToAdd: 'Количество (Максимум воды для добавления: {maxWaterQuantity})',
  unloadingMethod: 'Метод разгрузки',
  time: 'Время',
  eventStatus: 'Статус',
  MTQ_abbr: 'м\u00B3',
  EA_abbr: 'шт',
  LTR_abbr: 'л',
  YDQ_abbr: 'ярд³',
  GLL_abbr: 'гал',
  MTQ_text: 'Кубические метры',
  M3_text: 'Кубические метры',
  EA_text: 'Штука',
  LTR_text: 'Литры',
  YDQ_text: 'Кубические ярды',
  GLL_text: 'Галлоны',
  ACR_text: 'Акры',
  HUR_text: 'Час',
  INH_text: 'Дюймы',
  FOT_text: 'Фут',
  YRD_text: 'Ярд',
  MMT_text: 'Миллиметр',
  CMT_text: 'Сантиметр',
  MTR_text: 'Метр',
  SMI_text: 'Миля (статутная миля)',
  KMT_text: 'Километр',
  INK_text: 'Квадратный дюйм',
  FTK_text: 'Квадратный фут',
  YDK_text: 'Квадратный ярд',
  MIK_text: 'Квадратная миля',
  CMK_text: 'Квадратный сантиметр',
  MTK_text: 'Квадратный метр',
  KMK_text: 'Квадратный километр',
  INQ_text: 'Кубический дюйм',
  MMQ_text: 'Кубический миллиметр',
  FTQ_text: 'Кубический фут',
  CMQ_text: 'Кубический сантиметр',
  OZA_text: 'Жидкая унция',
  PT_text: 'Пинта',
  QT_text: 'Кварта',
  MLT_text: 'Миллилитр',
  ONZ_text: 'Унция',
  LBR_text: 'Фунт',
  STN_text: 'Тонна',
  GRM_text: 'Грамм',
  KGM_text: 'Килограмм',
  TNE_text: 'Тонна',
  PS_text: 'Фунты на квадратный дюйм',
  MPA_text: 'Мегапаскаль',
  C56_text: 'Ньютон на квадратный миллиметр',
  C62_text: 'Один',
  NL_text: 'Загрузка',
  MT_text: 'Мат',
  P1_text: 'Процент',
  CEL_text: 'Градус Цельсия',
  FAH_text: 'Градус Фаренгейта',
  BLL_text: 'Баррель',
  CLT_text: 'Сантилитр',
  DLT_text: 'Децилитр',
  GLI_text: 'Имперский галлон',
  '2U_text': 'Мегаграмм',
  SA_text: 'Мешок',
  '60_text': 'Процент веса',
  BG_text: 'Мешок',
  SEC_text: 'Секунда',
  MIN_text: 'Минута',
  '64_text': 'Фунт на квадратный дюйм - манометр',
  SP_text: 'Полочный пакет',
  MMK_text: 'Квадратный миллиметр',
  MTN_text: 'Метрическая тонна',
  GT_text: 'Гигатонна',
  '/NL_text': 'За загрузку',
  '/EA_text': 'За количество',
  '/H/ + h_text': 'За час',
  '/YQ/ + YDQ_text': 'За кубический ярд',
  '/MQ/ + MTQ_text': 'За кубический метр',
  '/MN/ + MIN_text': 'За минуту',
  '/MT_text': 'За метр',
  DSG_text: 'Дозировка',
  '/C_text': 'За 100 вес',
  '64_2_text': 'Специфический',
  MTQ: `{value, plural,
    =0 {0 м\u00B3}
    one {1 м\u00B3}
    other {# м\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 м\u00B3}
    one {1 м\u00B3}
    other {# м\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 литров}
    one {1 литр}
    other {# литров}
  }`,
  TO: `{value, plural,
    =0 {0 тонн}
    one {1 тонна}
    other {# тонн}
  }`,
  ITEM: `{value, plural,
    =0 {0 предметов}
    one {1 предмет}
    other {# предметов}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 ярд³}
    one {1 ярд³}
    other {# ярд³}
  }`,
  YDQ: `{value, plural,
    =0 {0 кубических ярдов}
    one {1 кубический ярд}
    other {# кубических ярдов}
  }`,
  GLL: `{value, plural,
    =0 {0 галлонов}
    one {1 галлон}
    other {# галлонов}
  }`,
  EA: `{value, plural,
    =0 {0 штук}
    one {1 штука}
    other {# штук}
  }`,
  MIN: `{value, plural,
    =0 {0 минут}
    one {1 минута}
    other {# минут}
  }`,
  ACR: `{value, plural,
    =0 {0 акров}
    one {1 акр}
    other {# акров}
  }`,
  HUR: `{value, plural,
    =0 {0 часов}
    one {1 час}
    other {# часов}
  }`,
  INH: `{value, plural,
    =0 {0 дюймов}
    one {1 дюйм}
    other {# дюймов}
  }`,
  FOT: `{value, plural,
    =0 {0 футов}
    one {1 фут}
    other {# футов}
  }`,
  YRD: `{value, plural,
    =0 {0 ярдов}
    one {1 ярд}
    other {# ярдов}
  }`,
  MMT: `{value, plural,
    =0 {0 миллиметров}
    one {1 миллиметр}
    other {# миллиметров}
  }`,
  CMT: `{value, plural,
    =0 {0 сантиметров}
    one {1 сантиметр}
    other {# сантиметров}
  }`,
  MTR: `{value, plural,
    =0 {0 метров}
    one {1 метр}
    other {# метров}
  }`,
  SMI: `{value, plural,
    =0 {0 миль (статутных миль)}
    one {1 миля (статутная миля)}
    other {# миль (статутных миль)}
  }`,
  KMT: `{value, plural,
    =0 {0 километров}
    one {1 километр}
    other {# километров}
  }`,
  INK: `{value, plural,
    =0 {0 квадратных дюймов}
    one {1 квадратный дюйм}
    other {# квадратных дюймов}
  }`,
  FTK: `{value, plural,
    =0 {0 квадратных футов}
    one {1 квадратный фут}
    other {# квадратных футов}
  }`,
  YDK: `{value, plural,
    =0 {0 квадратных ярдов}
    one {1 квадратный ярд}
    other {# квадратных ярдов}
  }`,
  MIK: `{value, plural,
    =0 {0 квадратных миль}
    one {1 квадратная миля}
    other {# квадратных миль}
}`,
  CMK: `{value, plural,
    =0 {0 квадратных сантиметров}
    one {1 квадратный сантиметр}
    other {# квадратных сантиметров}
  }`,
  MTK: `{value, plural,
    =0 {0 квадратных метров}
    one {1 квадратный метр}
    other {# квадратных метров}
  }`,
  KMK: `{value, plural,
    =0 {0 квадратных километров}
    one {1 квадратный километр}
    other {# квадратных километров}
  }`,
  INQ: `{value, plural,
    =0 {0 кубических дюймов}
    one {1 кубический дюйм}
    other {# кубических дюймов}
  }`,
  MMQ: `{value, plural,
    =0 {0 кубических миллиметров}
    one {1 кубический миллиметр}
    other {# кубических миллиметров}
  }`,
  FTQ: `{value, plural,
    =0 {0 кубических футов}
    one {1 кубический фут}
    other {# кубических футов}
  }`,
  CMQ: `{value, plural,
    =0 {0 кубических сантиметров}
    one {1 кубический сантиметр}
    other {# кубических сантиметров}
}`,
  OZA: `{value, plural,
    =0 {0 жидких унций}
    one {1 жидкая унция}
    other {# жидких унций}
  }`,
  PT: `{value, plural,
    =0 {0 пинт}
    one {1 пинта}
    other {# пинт}
  }`,
  QT: `{value, plural,
    =0 {0 кварт}
    one {1 кварта}
    other {# кварт}
  }`,
  MLT: `{value, plural,
    =0 {0 миллилитров}
    one {1 миллилитр}
    other {# миллилитров}
  }`,
  ONZ: `{value, plural,
    =0 {0 унций}
    one {1 унция}
    other {# унций}
  }`,
  LBR: `{value, plural,
    =0 {0 фунтов}
    one {1 фунт}
    other {# фунтов}
  }`,
  STN: `{value, plural,
    =0 {0 тонн}
    one {1 тонна}
    other {# тонн}
}`,
  GRM: `{value, plural,
    =0 {0 граммов}
    one {1 грамм}
    other {# граммов}
  }`,
  KGM: `{value, plural,
    =0 {0 килограммов}
    one {1 килограмм}
    other {# килограммов}
  }`,
  TNE: `{value, plural,
    =0 {0 тонн}
    one {1 тонна}
    other {# тонн}
  }`,
  PS: `{value, plural,
    =0 {0 psi}
    one {1 psi}
    other {# psi}
  }`,
  MPA: `{value, plural,
    =0 {0 мегапаскалей}
    one {1 мегапаскаль}
    other {# мегапаскалей}
  }`,
  C56: `{value, plural,
    =0 {0 Ньютонов на квадратный миллиметр}
    one {1 Ньютон на квадратный миллиметр}
    other {# Ньютонов на квадратный миллиметр}
}`,
  C62: `{value, plural,
    =0 {0 Один}
    one {1 Один}
    other {# Один}
  }`,
  NL: `{value, plural,
    =0 {0 Загрузок}
    one {1 Загрузка}
    other {# Загрузок}
  }`,
  MT: `{value, plural,
    =0 {0 Матов}
    one {1 Мат}
    other {# Мат}
  }`,
  P1: `{value, plural,
    =0 {0 Процентов}
    one {1 Процент}
    other {# Процентов}
  }`,
  CEL: `{value, plural,
    =0 {0 Градусов Цельсия}
    one {1 Градус Цельсия}
    other {# Градусов Цельсия}
  }`,
  FAH: `{value, plural,
    =0 {0 Градусов Фаренгейта}
    one {1 Градус Фаренгейта}
    other {# Градусов Фаренгейта}
}`,
  BLL: `{value, plural,
    =0 {0 Баррелей}
    one {1 Баррель}
    other {# Баррелей}
  }`,
  CLT: `{value, plural,
    =0 {0 сантилитров}
    one {1 сантилитр}
    other {# сантилитров}
}`,
  DLT: `{value, plural,
    =0 {0 децилитров}
    one {1 децилитр}
    other {# децилитров}
  }`,
  GLI: `{value, plural,
    =0 {0 галлонов}
    one {1 галлон}
    other {# галлонов}
  }`,
  '2U': `{value, plural,
    =0 {0 мегаграмм}
    one {1 мегаграмм}
    other {# мегаграмм}
  }`,
  SA: `{value, plural,
    =0 {0 мешков}
    one {1 мешок}
    other {# мешков}
  }`,
  60: `{value, plural,
    =0 {0 процентов веса}
    one {1 процент веса}
    other {# процентов веса}
  }`,
  BG: `{value, plural,
    =0 {0 мешков}
    one {1 мешок}
    other {# мешков}
  }`,
  SEC: `{value, plural,
    =0 {0 секунд}
    one {1 секунда}
    other {# секунд}
  }`,
  64: `{value, plural,
    =0 {0 фунтов на квадратный дюйм - манометр}
    one {1 фунт на квадратный дюйм - манометр}
    other {# фунтов на квадратный дюйм - манометр}
  }`,
  SP: `{value, plural,
    =0 {0 полочных пакетов}
    one {1 полочный пакет}
    other {# полочных пакетов}
  }`,
  MMK: `{value, plural,
    =0 {0 квадратных миллиметров}
    one {1 квадратный миллиметр}
    other {# квадратных миллиметров}
  }`,
  MTN: `{value, plural,
    =0 {0 метрических тонн}
    one {1 метрическая тонна}
    other {# метрических тонн}
  }`,
  GT: `{value, plural,
    =0 {0 гигатонн}
    one {1 гигатонна}
    other {# гигатонн}
  }`,
  '/NL': `{value, plural,
    =0 {0 За загрузку}
    one {1 За загрузку}
    other {# За загрузку}
  }`,
  '/EA': `{value, plural,
    =0 {0 За количество}
    one {1 За количество}
    other {# За количество}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 За час}
    one {1 За час}
    other {# За час}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 За кубический ярд}
    one {1 За кубический ярд}
    other {# За кубический ярд}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 За кубический метр}
    one {1 За кубический метр}
    other {# За кубический метр}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 За минуту}
    one {1 За минуту}
    other {# За минуту}
  }`,
  '/MT': `{value, plural,
    =0 {0 За метр}
    one {1 За метр}
    other {# За метр}
  }`,
  DSG: `{value, plural,
    =0 {0 Дозировка}
    one {1 Дозировка}
    other {# Дозировка}
  }`,
  '/C': `{value, plural,
    =0 {0 За 100 вес}
    one {1 За 100 вес}
    other {# За 100 вес}
  }`,
  '64_2': `{value, plural,
    =0 {0 Специфический}
    one {1 Специфический}
    other {# Специфический}
  }`,
  LTR_short: `{value, plural,
    =0 {0 L}
    one {1 L}
    other {# L}
  }`,
  TO_short: `{value, plural,
    =0 {0 ton}
    one {1 ton}
    other {# ton}
  }`,
  ITEM_short: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,
  GLL_short: `{value, plural,
    =0 {0 gal}
    one {1 gal}
    other {# gal}
  }`,
  EA_short: `{value, plural,
    =0 {0 ea}
    one {1 ea}
    other {# ea}
  }`,
  MIN_short: `{value, plural,
    =0 {0 min}
    one {1 min}
    other {# min}
  }`,
  ACR_short: `{value, plural,
    =0 {0 ac}
    one {1 ac}
    other {# ac}
  }`,
  HUR_short: `{value, plural,
    =0 {0 hr}
    one {1 hr}
    other {# hr}
  }`,
  INH_short: `{value, plural,
    =0 {0 in}
    one {1 in}
    other {# in}
  }`,
  FOT_short: `{value, plural,
    =0 {0 ft}
    one {1 ft}
    other {# ft}
  }`,
  YRD_short: `{value, plural,
    =0 {0 yd}
    one {1 yd}
    other {# yd}
  }`,
  MMT_short: `{value, plural,
    =0 {0 mm}
    one {1 mm}
    other {# mm}
  }`,
  CMT_short: `{value, plural,
    =0 {0 cm}
    one {1 cm}
    other {# cm }
  }`,
  MTR_short: `{value, plural,
    =0 {0 m}
    one {1 m}
    other {# m}
  }`,
  SMI_short: `{value, plural,
    =0 {0 Miles (st mi)}
    one {1 Mile ((st mi)}
    other {# Miles ((st mi)}
  }`,
  KMT_short: `{value, plural,
    =0 {0 km}
    one {1 km}
    other {# km}
  }`,
  INK_short: `{value, plural,
    =0 {0 in²}
    one {1 in²}
    other {# in²}
  }`,
  FTK_short: `{value, plural,
    =0 {0 ft²}
    one {1 ft²}
    other {# ft²}
  }`,
  YDK_short: `{value, plural,
    =0 {0 yd²}
    one {1 yd²}
    other {# yd²}
  }`,
  MIK_short: `{value, plural,
    =0 {0 mi²}
    one {1 mi²}
    other {# mi²}
  }`,
  CMK_short: `{value, plural,
    =0 {0 cm²}
    one {1 cm²}
    other {# cm²}
  }`,
  MTK_short: `{value, plural,
    =0 {0 m²}
    one {1 m²}
    other {# m²}
}`,
  KMK_short: `{value, plural,
    =0 {0 km²}
    one {1 km²}
    other {# km²}
  }`,
  INQ_short: `{value, plural,
    =0 {0 in³}
    one {1 in³}
    other {# in³}
  }`,
  MMQ_short: `{value, plural,
    =0 {0 mm³}
    one {1 mm³}
    other {# mm³}
  }`,
  FTQ_short: `{value, plural,
    =0 {0 ft³}
    one {1 ft³}
    other {# ft³}
  }`,
  CMQ_short: `{value, plural,
    =0 {0 cm³}
    one {1 cm³}
    other {# cm³}
  }`,
  OZA_short: `{value, plural,
    =0 {0 fl oz}
    one {1 fl oz}
    other {# fl oz}
  }`,
  PT_short: `{value, plural,
    =0 {0 pt}
    one {1 pt}
    other {# pt}
  }`,
  QT_short: `{value, plural,
    =0 {0 qt}
    one {1 qt}
    other {# qt}
  }`,
  MLT_short: `{value, plural,
    =0 {0 mL}
    one {1 mL}
    other {# mL}
  }`,
  ONZ_short: `{value, plural,
    =0 {0 oz}
    one {1 oz}
    other {# oz}
}`,
  LBR_short: `{value, plural,
    =0 {0 lb}
    one {1 lb}
    other {# lb}
  }`,
  STN_short: `{value, plural,
    =0 {0 ton}
    one {1 ton}
    other {# ton}
  }`,
  GRM_short: `{value, plural,
    =0 {0 g}
    one {1 g}
    other {# g}
  }`,
  KGM_short: `{value, plural,
    =0 {0 kg}
    one {1 kg}
    other {# kg}
  }`,
  TNE_short: `{value, plural,
    =0 {0 t}
    one {1 t}
    other {# t}
  }`,
  PS_short: `{value, plural,
    =0 {0 psi}
    one {1 psi}
    other {# psi}
  }`,
  MPA_short: `{value, plural,
    =0 {0 mpa}
    one {1 mpa}
    other {# mpa}
  }`,
  C56_short: `{value, plural,
    =0 {0 Newtons Per Square Millimeter}
    one {1 Newton Per Square Millimeter}
    other {# Newtons Per Square Millimeter}
  }`,
  C62_short: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL_short: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT_short: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1_short: `{value, plural,
    =0 {0 %}
    one {1 %}
    other {# %}
  }`,
  CEL_short: `{value, plural,
    =0 {0 c}
    one {1 c}
    other {# c}
  }`,
  FAH_short: `{value, plural,
    =0 {0 f}
    one {1 f}
    other {# f}
  }`,
  BLL_short: `{value, plural,
    =0 {0 bbl}
    one {1 bbl}
    other {# bbl}
  }`,
  CLT_short: `{value, plural,
    =0 {0 cL}
    one {1 cL}
    other {# cL}
  }`,
  DLT_short: `{value, plural,
    =0 {0 dL}
    one {1 dL}
    other {# dL}
  }`,
  GLI_short: `{value, plural,
    =0 {0 gal}
    one {1 gal}
    other {# gal}
  }`,
  '2U_short': `{value, plural,
    =0 {0 мегаграмм}
    one {1 мегаграмм}
    other {# мегаграмм}
  }`,
  SA_short: `{value, plural,
    =0 {0 мешков}
    one {1 мешок}
    other {# мешков}
  }`,
  '60_short': `{value, plural,
    =0 {0 % веса}
    one {1 % веса}
    other {# % веса}
  }`,
  BG_short: `{value, plural,
    =0 {0 мешков}
    one {1 мешок}
    other {# мешков}
  }`,
  SEC_short: `{value, plural,
    =0 {0 сек}
    one {1 сек}
    other {# сек}
  }`,
  '64_short': `{value, plural,
    =0 {0 фунтов на квадратный дюйм - манометр}
    one {1 фунт на квадратный дюйм - манометр}
    other {# фунтов на квадратный дюйм - манометр}
  }`,
  SP_short: `{value, plural,
    =0 {0 полочных пакетов}
    one {1 полочный пакет}
    other {# полочных пакетов}
  }`,
  MMK_short: `{value, plural,
    =0 {0 квадратных миллиметров}
    one {1 квадратный миллиметр}
    other {# квадратных миллиметров}
  }`,
  MTN_short: `{value, plural,
    =0 {0 метрических тонн}
    one {1 метрическая тонна}
    other {# метрических тонн}
  }`,
  GT_short: `{value, plural,
    =0 {0 гигатонн}
    one {1 гигатонна}
    other {# гигатонн}
  }`,
  '/NL_short': `{value, plural,
    =0 {0 /загрузку}
    one {1 /загрузку}
    other {# /загрузку}
  }`,
  '/EA_short': `{value, plural,
    =0 {0 /количество}
    one {1 /количество}
    other {# /количество}
  }`,
  '/H/ + h_short': `{value, plural,
    =0 {0 /час}
    one {1 /час}
    other {# /час}
  }`,
  '/YQ/ + YDQ_short': `{value, plural,
    =0 {0 /кубический ярд}
    one {1 /кубический ярд}
    other {# /кубический ярд}
  }`,
  '/MQ/ + MTQ_short': `{value, plural,
    =0 {0 /м³}
    one {1 /м³}
    other {# /м³}
}`,
  '/MN/ + MIN_short': `{value, plural,
    =0 {0 /мин}
    one {1 /мин}
    other {# /мин}
  }`,
  '/MT_short': `{value, plural,
    =0 {0 /м}
    one {1 /м}
    other {# /м}
  }`,
  DSG_short: `{value, plural,
    =0 {0 дозировка}
    one {1 дозировка}
    other {# дозировка}
  }`,
  '/C_short': `{value, plural,
    =0 {0 /100 веса}
    one {1 /100 веса}
    other {# /100 веса}
  }`,
  '64_2_short': `{value, plural,
    =0 {0 специфический}
    one {1 специфический}
    other {# специфический}
  }`,
  mixIdLabel: 'ID смеси/Описание',
  orderedQtyLabel: 'Заказанное количество',
  loadedQtyLabel: 'Загруженное количество',
  addedByCustomer: 'Добавлено клиентом',
  PRINTED: 'Билет напечатан',
  LOADING_STARTED: 'Начало загрузки',
  LOADING_COMPLETE: 'Загрузка завершена',
  TO_JOB: 'Покинул завод',
  ARRIVE_JOB: 'Прибыл на объект',
  UNLOADING: 'Начало разгрузки',
  END_UNLOADING: 'Разгрузка завершена',
  LEAVE_JOB: 'Покинул объект',
  IN_YARD: 'На территории',
  PUMPING_STARTED: 'Начало перекачки',
  PUMPING_COMPLETE: 'Перекачка завершена',
  READY_TO_PUMP: 'Готов к перекачке',
  FIRST_WCCONTACT: 'Первый контакт с водой и цементом',
  plantName: 'Название завода',
  plantAddress: 'Адрес завода',
  loadingScheduleStart: 'Запланированное время загрузки',
  deliveryAddress: 'Адрес доставки',
  phoneNumber: 'Номер телефона',
  unloadingScheduleStart: 'Запланированное время разгрузки (ETA)',
  agreedUnloading: 'Заказанные времена разгрузки',
  deliveryInformation: 'Информация о доставке',
  next: 'Далее',
  material: 'Материал',
  loadingPoint: 'Точка загрузки',
  unloadingPoint: 'Точка разгрузки',
  comment: 'Комментарий',
  sapDeliveryNumber: 'Номер доставки SAP (номер BCC)',
  salesOrderNumber: 'Номер заказа и строка товара',
  wcDateTime: 'Дата и время контакта с водой и цементом',
  po: 'Номер заказа',
  orderedByName: 'Заказ размещен на имя',
  orderedByPhone: 'Номер телефона заказчика',
  resourceId: 'ID ресурса',
  totalLoadedQty: 'Общее загруженное количество, включая эту загрузку',
  spacing: 'Пространство для загрузки',
  deliveryFlow: 'Поток доставки',
  timeOnSite: 'Общее время на объекте/заказанное время разгрузки',
  salesText: 'Текст продажи (ID материала)',
  strengthClass: 'Класс прочности',
  environmentExposure: 'Воздействие окружающей среды',
  metExposureClasses: 'Соответствующие классы воздействия',
  dmax: 'Dmax',
  standardCert: 'Стандарт (сертификация)',
  workabilityTarget: 'Цель по удобоукладываемости',
  stoneQuality: 'Качество камня',
  sandQuality: 'Качество песка',
  targetWC: 'Целевое соотношение вода/цемент по рецепту',
  controlClass: 'Класс контроля',
  chlorideClass: 'Класс хлоридов',
  typeAndStrength1: 'Тип цемента и класс прочности 1',
  typeAndStrength2: 'Тип цемента и класс прочности 2',
  typeOfAddition: 'Тип добавки',
  typeOfAdmixture: 'Тип примеси',
  orderedTemp: 'Заказанная температура',
  fibers1: 'Тип и содержание волокон 1',
  fibers2: 'Тип и содержание волокон 2',
  calculatedMaturityTime: 'Рассчитанное время созревания с момента контакта с водой и цементом',
  mixIdentity: 'Идентификация смеси',
  grade: 'Класс',
  mixType: 'Тип смеси',
  maxAggSize: 'Максимальный размер заполнителя',
  maxAggType: 'Максимальный тип заполнителя',
  cementType: 'Тип цемента',
  admixtures: 'Примеси',
  addmixDescription: 'Описание примеси',
  slump: 'Осадка',
  minCementContent: 'Минимальное содержание цемента',
  maxWatCemRatio: 'Максимальное соотношение вода/цемент',
  maxWaterToAdd: 'Максимальное количество добавляемой воды',
  mortarMinWorkingLife: 'Минимальный срок службы раствора',
  code: 'Код',
  dcClass: 'Класс DC',
  chloride: 'Хлорид',
  signedByHanson: 'Подписано Хансоном',
  warmStamp: 'Теплая печать',
  coldStamp: 'Холодная печать',
  containsSikatard: 'Содержит Sikatard',
  containsDelvo: 'Содержит Delvo',
  generalDelivery: 'Общая информация о доставке',
  materialInfo: 'Информация о материале',
  water: 'Вода',
  materialService: 'Материал / Услуга',
  returnedMaterial: 'Возвращенный материал',
  returnedConcrete: 'Возвращенный бетон',
  waterAdded: 'Добавленная вода',
  concreteOnTruck: 'Бетон на грузовике',
  concreteOnTruckUom: 'Единица измерения бетона на грузовике по умолчанию',
  reason: 'Причина',
  customerAddedWater: 'Вода, добавленная клиентом',
  addWater: 'Добавить воду',
  addedAt: 'Добавлено в',
  addMaterialService: 'Добавить материал/услугу',
  customerAddedProduct: 'Продукт, добавленный клиентом',
  addReturnedMaterial: 'Добавить возвращенный материал',
  leftover: 'Остаток',
  submitAcceptance: 'Подтвердить принятие',
  accepted: 'Принято',
  rejected: 'Отклонено',
  customerAcceptance: 'Принятие клиентом',
  finalize: 'Завершить',
  materialSummary: 'Сводка по материалам',
  mix: 'Смесь',
  ordered: 'Заказано',
  loaded: 'Загружено',
  totalOrdered: 'Общее количество заказа',
  loadedOrderQuantity: 'Загруженное количество заказа',
  loadedOrder: 'Загруженный заказ',
  driverComment: 'Комментарий водителя',
  customerComment: 'Комментарий клиента',
  editComment: 'Редактировать комментарий',
  addComment: 'Добавить комментарий',
  additionalInfo: 'Дополнительная информация',
  onBoard: 'На борту',
  driver: 'Водитель',
  commentInstruction: 'Инструкция по комментарию',
  signatureInstruction: 'Подпись клиента',
  customerSignature: 'Подпись клиента',
  general: 'Общее',
  status: 'Статус',
  updateStatusTime: 'Обновить время статуса',
  delete: 'Удалить',
  cancel: 'Отменить',
  back: 'Назад',
  add: 'Добавить',
  edit: 'Редактировать',
  done: 'Готово',
  ok: 'ОК',
  decline: 'Отклонить',
  Submit: 'Отправить',
  submit: 'Отправить',
  lastRefresh: 'Последнее обновление',
  refreshEnabled: 'Включить обновление',
  id: 'ID',
  name: 'Имя',
  invoiceable: 'Подлежащий оплате',
  provideSignature: 'Предоставить подпись',
  ticket: 'Билет',
  details: 'Детали',
  qrCode: 'QR-код',
  driverAcceptanceReason: 'Причина',
  driverAcceptance: 'Принятие водителем',
  'payment-method': 'Способы оплаты',
  'add-payment-method': 'Добавить способ оплаты',
  'reason-code': 'Код причины',
  'add-reason-code': 'Добавить код причины',
  'qr-code': 'QR-код',
  'unloading-method': 'Методы разгрузки',
  'add-unloading-method': 'Добавить метод разгрузки',
  'driver-comment': 'Комментарии водителя',
  'add-driver-comment': 'Добавить комментарий водителя',
  'driver-sellable': 'Продаваемое водителем',
  vehicle: 'Транспортные средства',
  'add-vehicle': 'Добавить транспортное средство',
  'vehicle-type': 'Типы транспортных средств',
  vehicleTypes: 'Типы транспортных средств',
  vehicleType: 'Тип транспортного средства',
  'add-vehicle-type': 'Добавить тип транспортного средства',
  countries: 'Страны',
  country: 'Страна',
  type: 'Тип',
  uom: 'Единица измерения',
  mandatory: 'Обязательно',
  qrTemplate: 'Шаблон QR-кода',
  includeMixInfo: 'Включить информацию о смеси в QR-код',
  includeTicketEvents: 'Включить события билета в QR-код',
  quantityField: 'Включить поле количества',
  concreteOnTruckField: 'Включить поле бетона на грузовике',
  materialOnTruckField: 'Включить поле материала на грузовике',
  timeField: 'Включить поле времени',
  reasonField: 'Включить поле причины',
  driverInstructions: 'Инструкции для водителя',
  waitingForTicket: 'Ожидание билета...',
  createTestTicketForTruck: 'Создать тестовый билет для грузовика',
  exceedsLoadedQuantity: 'Значение не может превышать загруженное количество {loadedQuantity}.',
  maxQuantity: 'Максимальное количество',
  maxQuantityError: 'Количество не может превышать определенное максимальное количество {maxQty}',
  maxValueError: 'Значение не может превышать определенный максимум {maxQty}',
  minValueError: 'Значение не может быть меньше определенного минимума {minQty}',
  notAuthorized: 'Не авторизован',
  enterTruckNumber: 'Введите номер грузовика',
  setTruckNumber: 'Установить номер грузовика',
  commentList: 'Список комментариев',
  list: 'Список',
  gridPreferences: 'Настройки сетки',
  loadingUnloadingPoints: 'Точки загрузки/разгрузки',
  activity: 'Активность',
  crn: 'CRN',
  update: 'Обновить',
  CONCRETE: 'Бетон',
  WATER: 'Вода',
  ADDITIVE: 'Добавка',
  'submit-signature-button': 'Принять',
  'cancel-signature-button': 'Отменить',
  uomCode: 'Единица измерения по умолчанию',
  'product-type': 'Типы продуктов',
  'add-product-type': 'Добавить тип продукта',
  'add-product': 'Добавить продукт',
  'setup_driver-comment': 'Комментарии водителя',
  setup_product: 'Продукты',
  'setup_product-type': 'Типы продуктов',
  'setup_qr-code': 'QR-код',
  'setup_reason-code': 'Коды причин',
  'setup_unloading-method': 'Методы разгрузки',
  'setup_entity-mt': 'Компания',
  setup_vehicle: 'Транспортные средства',
  'setup_vehicle-type': 'Типы транспортных средств',
  'setup_data-retentions': 'Сохранение данных',
  'add-data-retentions': 'Добавить сохранение данных',
  'data-retentions': 'Сохранение данных',
  setup_carriers: 'Перевозчики',
  'add-carriers': 'Добавить перевозчиков',
  carriers: 'Перевозчики',
  'setup_concrete-classes': 'Соответствие материалу',
  'add-concrete-classes': 'Добавить соответствие материалу',
  'concrete-classes': 'Соответствие материалу',
  'setup_forms-and-waivers': 'Формы и отказы',
  'add-forms-and-waivers': 'Добавить формы и отказы',
  'forms-and-waivers': 'Формы и отказы',
  companyFleet: 'Флот компании',
  category: 'Категория',
  interval: 'Интервал',
  orders: 'Заказы и билеты',
  '30days': '30 дней',
  '60days': '60 дней',
  '90days': '90 дней',
  '1year': '1 год',
  '3years': '3 года',
  waterMandatory: 'Вода обязательна',
  qrCodeTemplate: 'Шаблон QR-кода',
  waterConfig: 'Дополнительные поля',
  waterProduct: 'Вода',
  waterProductType: 'Тип водного продукта',
  defaultWaterProduct: 'Водный продукт по умолчанию',
  invalidTemplate: 'Недействительный шаблон',
  siteAddress: 'Адрес объекта',
  siteContactName: 'Имя контактного лица на объекте',
  siteContactPhoneNumber: 'Номер телефона контактного лица на объекте',
  'clear-signature-button': 'Очистить подпись',
  customerCommentEnabled: 'Включить комментарий клиента',
  driverCommentEnabled: 'Включить комментарий водителя',
  driverCommentMaxCharacters: 'Максимальная длина',
  customerCommentMaxCharacters: 'Максимальная длина',
  timeZone: 'Часовой пояс',
  driverDidNotAdd: 'Водитель не добавил',
  maxLineItems: 'Максимальное количество строк',
  oneLineItem: 'Должен быть хотя бы один элемент',
  signatureSiteContact: 'Имя клиента',
  ticketEvents: 'События билета',
  hideEvent: 'Скрыть событие',
  editable: 'Редактируемый',
  ticketDetail: `Билет #{value}`,
  statusTimes: 'Времена статуса',
  materialAndDeliveryInfo: 'Информация о материале и доставке',
  customerRejection: 'Отклонение клиента',
  'submit-rejection-button': 'Отправить отклонение',
  accept: 'Принять',
  reject: 'Отклонить загрузку',
  signature: 'Подпись',
  'no-signature': 'Без подписи',
  unFinalizePassword: 'Пароль для отмены завершения',
  waterInWorkflow: 'Включить этап воды в рабочем процессе',
  multicountry: 'Многостраничный',
  timestampAfterSequence: 'ПРЕДУПРЕЖДЕНИЕ: Время не должно быть раньше предыдущей отметки времени',
  timestampBeforeSequence: 'ПРЕДУПРЕЖДЕНИЕ: Время не должно быть позже следующей отметки времени',
  noRestQuantity: 'Нет возвращенного материала',
  returnedMaterialMandatory: 'Необходимо взаимодействовать с этапом возвращенного материала',
  driverCommentMaxChars: 'Длина не может превышать максимум {maxLength} символов.',
  QrCodeEnabled: 'Включить QR-код',
  QrMethod_linkBased: 'На основе ссылки',
  QrMethod_contentBased:
    'На основе содержимого, ПРИМЕЧАНИЕ: QR-коды на основе содержимого имеют ограничения по количеству символов, что может вызвать проблемы при превышении 1024 символов.',
  QrMethod_contentBasedWarning:
    'ПРИМЕЧАНИЕ: Этот QR-код содержит {amount} символов, которые могут быть не читаемы некоторыми телефонами.',
  returnedMaterialInWorkflow: 'Включить этап возвращенного материала в рабочем процессе',
  materialServiceInWorkflow: 'Включить этап материала/услуги в рабочем процессе',
  totalPumpedQuantity: 'Общее количество перекачанного',
  concrete: 'Бетон',
  materialComplianceEnabled: 'Включить соответствие материалу',
  pumpStatusTimes: 'Времена статуса насоса',
  pollingInterval: 'Интервал опроса (в секундах)',
  tenSecs: 'Интервал должен быть не менее 10 секунд',
  materialCompliance: 'Соответствие материалу',
  materialCompliance_notFound: 'Соответствие материалу не найдено',
  consistenceValue: 'Консистенция - осадка',
  consistenceIsCompliant: 'Консистенция соответствует BS8500-2',
  airContentValue: 'Содержание воздуха',
  airContentIsCompliant: 'Содержание воздуха соответствует BS8500-2',
  consistenceIsCompliantShort: 'Консистенция соответствует',
  airContentIsCompliantShort: 'Содержание воздуха соответствует',
  compliance: 'Соответствие материалу с BS8500-2',
  termsAndConditionsEnabled: 'Включить форму условий и положений',
  termsAndConditions: 'Условия и положения',
  damageWaiverEnabled: 'Включить форму отказа от ответственности за повреждения',
  damageWaiver: 'Отказ от ответственности за повреждения',
  damageWaiverAcknowledged: 'Отказ от ответственности за повреждения подтвержден',
  dynamicWaiver: 'Динамический отказ',
  dynamicWaiverEnabled: 'Включить форму динамического отказа',
  ticketListEnabled: 'Включить список билетов',
  clearTicketAfterSubmission: 'Очистить билет после отправки',
  keepTicketOpenAfterSubmission: 'Оставить билет открытым после отправки',
  displayTicketList: 'Отобразить список билетов',
  supportedLanguages: 'Поддерживаемые языки',
  allow: 'Разрешить',
  default: 'По умолчанию',
  isCertificationLossTriggerForReason: 'Причина для заметки о потере сертификации',
  isCertificationLossTriggerForProduct: 'Продукт для заметки о потере сертификации',
  translations: 'Переводы',
  family_name: 'Фамилия',
  given_name: 'Имя',
  email: 'Электронная почта',
  role: 'Роль',
  inviteUser: 'Пригласить пользователя',
  carrier: 'Перевозчик',
  emailOrPhoneNumber: 'Электронная почта или номер телефона',
  usersEditor: 'Редактор пользователей',
  verifyPassword: 'Подтвердить пароль',
  verificationCode: 'Код подтверждения',
  register: 'Зарегистрироваться',
  registerInstructions: 'Пожалуйста, заполните эту форму, чтобы завершить регистрацию.',
  'password-requirements':
    'Ваш пароль должен содержать не менее 8 символов, включать как минимум 1 заглавную букву, 1 строчную букву, 1 цифру и 1 специальный символ.',
  passwordMinCharacters: 'Пароль должен содержать 8 или более символов.',
  passwordMaxCharacters: 'Пароль не может содержать более 99 символов.',
  passwordLowerCase: 'Пароль должен включать 1 или более строчных букв.',
  passwordUpperCase: 'Пароль должен включать 1 или более заглавных букв.',
  passwordNumber: 'Пароль должен включать 1 или более числовых символов.',
  passwordSpecialCharacter: 'Пароль должен включать 1 или более специальных символов.',
  passwordsDontMatch: 'Пароли не совпадают!',
  success: 'Успех!',
  'registration-success': 'Ваша регистрация прошла успешно.',
  'post-registration-instructions': 'Вы завершили процесс регистрации. Теперь вы можете войти в систему.',
  createDate: 'Дата создания',
  customerPo: 'Номер заказа клиента',
  specification: 'Спецификация',
  description: 'Описание',
  classType: 'Тип класса',
  minimumValue: 'Минимальное значение',
  maximumValue: 'Максимальное значение',
  TrackIt: 'TrackIt',
  trackIt3PContext: 'Контекст TrackIt 3P',
  inviteLanguage: 'Язык приглашения',
  truckNumber: 'Номер грузовика',
  adverseTemperature:
    'Температура окружающей среды во время смешивания была такой, что мы не можем гарантировать, что бетон будет в диапазоне температур 5-35°C, как указано в EN 206.',
  environmentalNote:
    'Эта загрузка содержит экологический агент, который не превышает 0,03% по весу бетона. Вода, содержащая этот материал для производства бетона, будет соответствовать BS EN 1008.',
  unloadingMethodEnabled: 'Включить метод разгрузки',
  warrantyVoided: 'Гарантия на материал аннулирована',
  materialWarrantyVoidedEnabled:
    'Когда используется продукт или код причины для заметки о потере сертификации, показывать заметку о потере сертификации при подписании.',
  'setup_templates-mt': 'Шаблоны',
  copyFrom: 'Скопировать из...',
  copy: 'копировать',
  users: 'Пользователи',
  setup: 'Настройка',
  driverExperience: 'Опыт водителя',
  ticketList: 'Список билетов',
  content: 'Содержание',
  WARNINGS: 'Предупреждения',
  TERMS: 'Условия',
  DAMAGE: 'Повреждение',
  ADDITIONALWAIVER: 'Дополнительный отказ',
  DYNAMICWAIVER: 'Динамический отказ',
  source: 'Источник',
  sensorUnable: 'Датчик не может определить количество остатка.',
  PROBE: 'Зонд',
  DRIVER: 'Водитель',
  DRS: 'DRS',
  waterReleaseFormEnabled: 'Включить форму выпуска воды',
  waterRelease: 'Выпуск воды',
  waterReleaseAcknowledged: 'Выпуск воды подтвержден',
  'assessment-risk': 'Оценка риска',
  'setup_assessment-risk': 'Оценка риска',
  'add-assessment-risk': 'Добавить оценку риска',
  enterRecipientsButton: 'Введите получателя(ей)',
  recipientsInstructions: 'Введите электронную почту, разделенную запятой',
  enter: 'Ввести',
  invalidEmailAddress: 'недействительный адрес электронной почты',
  personalDataPolicy:
    'Вводя личные данные в эту форму, вы подтверждаете, что у вас есть необходимые разрешения для передачи этих данных',
  subcategory: 'Подкатегория',
  isRequired: 'Обязательно',
  riskAssessmentEnabled: 'Включить оценку риска',
  riskAssessment: 'Оценка риска',
  yes: 'Да',
  no: 'Нет',
  sourceOptionsSensors: 'Опции источника (датчики)',
  sourceDcm: 'DCM',
  sourceProbe: 'Зонд',
  availableCustomValues: 'Доступные пользовательские значения',
  ERR_CODE__DUPLICATE: 'Дубликат',
  ERR_CODE__DEFAULT_VALUE: 'Уже существует значение по умолчанию',
  ERR_CODE__EXISTING_FORM: 'Уже существует активная форма для этого транспортного средства и типа',
  availableValues: 'Доступные значения',
  pump: 'Насос',
  DanskName: 'Датское имя',
  GermanName: 'Немецкое имя',
  EnglishName: 'Английское имя',
  BritainName: 'Британское имя',
  SpanishName: 'Испанское имя',
  FrenchName: 'Французское имя',
  NederlandsName: 'Нидерландское имя',
  NorskName: 'Норвежское имя',
  SvenskaName: 'Шведское имя',
  ItalianName: 'Итальянское имя',
  PolishName: 'Польское имя',
  DanskNameDefault: 'Датское имя (по умолчанию)',
  GermanNameDefault: 'Немецкое имя (по умолчанию)',
  EnglishNameDefault: 'Английское имя (по умолчанию)',
  BritainNameDefault: 'Британское имя (по умолчанию)',
  SpanishNameDefault: 'Испанское имя (по умолчанию)',
  FrenchNameDefault: 'Французское имя (по умолчанию)',
  NederlandsNameDefault: 'Нидерландское имя (по умолчанию)',
  NorskNameDefault: 'Норвежское имя (по умолчанию)',
  SvenskaNameDefault: 'Шведское имя (по умолчанию)',
  ItalianNameDefault: 'Итальянское имя (по умолчанию)',
  PolishNameDefault: 'Польское имя (по умолчанию)',
  DanskDescription: 'Датское описание',
  GermanDescription: 'Немецкое описание',
  EnglishDescription: 'Английское описание',
  BritainDescription: 'Британское описание',
  SpanishDescription: 'Испанское описание',
  FrenchDescription: 'Французское описание',
  NederlandsDescription: 'Нидерландское описание',
  NorskDescription: 'Норвежское описание',
  SvenskaDescription: 'Шведское описание',
  ItalianDescription: 'Итальянское описание',
  PolishDescription: 'Польское описание',
  DanskDescriptionDefault: 'Датское описание (по умолчанию)',
  GermanDescriptionDefault: 'Немецкое описание (по умолчанию)',
  EnglishDescriptionDefault: 'Английское описание (по умолчанию)',
  BritainDescriptionDefault: 'Британское описание (по умолчанию)',
  SpanishDescriptionDefault: 'Испанское описание (по умолчанию)',
  FrenchDescriptionDefault: 'Французское описание (по умолчанию)',
  NederlandsDescriptionDefault: 'Нидерландское описание (по умолчанию)',
  NorskDescriptionDefault: 'Норвежское описание (по умолчанию)',
  SvenskaDescriptionDefault: 'Шведское описание (по умолчанию)',
  ItalianDescriptionDefault: 'Итальянское описание (по умолчанию)',
  PolishDescriptionDefault: 'Польское описание (по умолчанию)',
  DanskContent: 'Датское содержание',
  GermanContent: 'Немецкое содержание',
  EnglishContent: 'Английское содержание',
  BritainContent: 'Британское содержание',
  SpanishContent: 'Испанское содержание',
  FrenchContent: 'Французское содержание',
  NederlandsContent: 'Нидерландское содержание',
  NorskContent: 'Норвежское содержание',
  SvenskaContent: 'Шведское содержание',
  ItalianContent: 'Итальянское содержание',
  PolishContent: 'Польское содержание',
  DanskContentDefault: 'Датское содержание (по умолчанию)',
  GermanContentDefault: 'Немецкое содержание (по умолчанию)',
  EnglishContentDefault: 'Английское содержание (по умолчанию)',
  BritainContentDefault: 'Британское содержание (по умолчанию)',
  SpanishContentDefault: 'Испанское содержание (по умолчанию)',
  FrenchContentDefault: 'Французское содержание (по умолчанию)',
  NederlandsContentDefault: 'Нидерландское содержание (по умолчанию)',
  NorskContentDefault: 'Норвежское содержание (по умолчанию)',
  SvenskaContentDefault: 'Шведское содержание (по умолчанию)',
  ItalianContentDefault: 'Итальянское содержание (по умолчанию)',
  PolishContentDefault: 'Польское содержание (по умолчанию)',
  DanskCategory: 'Датская категория',
  GermanCategory: 'Немецкая категория',
  EnglishCategory: 'Английская категория',
  BritainCategory: 'Британская категория',
  SpanishCategory: 'Испанская категория',
  FrenchCategory: 'Французская категория',
  NederlandsCategory: 'Нидерландская категория',
  NorskCategory: 'Норвежская категория',
  SvenskaCategory: 'Шведская категория',
  ItalianCategory: 'Итальянская категория',
  PolishCategory: 'Польская категория',
  DanskCategoryDefault: 'Датская категория (по умолчанию)',
  GermanCategoryDefault: 'Немецкая категория (по умолчанию)',
  EnglishCategoryDefault: 'Английская категория (по умолчанию)',
  BritainCategoryDefault: 'Британская категория (по умолчанию)',
  SpanishCategoryDefault: 'Испанская категория (по умолчанию)',
  FrenchCategoryDefault: 'Французская категория (по умолчанию)',
  NederlandsCategoryDefault: 'Нидерландская категория (по умолчанию)',
  NorskCategoryDefault: 'Норвежская категория (по умолчанию)',
  SvenskaCategoryDefault: 'Шведская категория (по умолчанию)',
  ItalianCategoryDefault: 'Итальянская категория (по умолчанию)',
  PolishCategoryDefault: 'Польская категория (по умолчанию)',
  DanskSubcategory: 'Датская подкатегория',
  GermanSubcategory: 'Немецкая подкатегория',
  EnglishSubcategory: 'Английская подкатегория',
  BritainSubcategory: 'Британская подкатегория',
  SpanishSubcategory: 'Испанская подкатегория',
  FrenchSubcategory: 'Французская подкатегория',
  NederlandsSubcategory: 'Нидерландская подкатегория',
  NorskSubcategory: 'Норвежская подкатегория',
  SvenskaSubcategory: 'Шведская подкатегория',
  ItalianSubcategory: 'Итальянская подкатегория',
  PolishSubcategory: 'Польская подкатегория',
  DanskSubcategoryDefault: 'Датская подкатегория (по умолчанию)',
  GermanSubcategoryDefault: 'Немецкая подкатегория (по умолчанию)',
  EnglishSubcategoryDefault: 'Английская подкатегория (по умолчанию)',
  BritainSubcategoryDefault: 'Британская подкатегория (по умолчанию)',
  SpanishSubcategoryDefault: 'Испанская подкатегория (по умолчанию)',
  FrenchSubcategoryDefault: 'Французская подкатегория (по умолчанию)',
  NederlandsSubcategoryDefault: 'Нидерландская подкатегория (по умолчанию)',
  NorskSubcategoryDefault: 'Норвежская подкатегория (по умолчанию)',
  SvenskaSubcategoryDefault: 'Шведская подкатегория (по умолчанию)',
  ItalianSubcategoryDefault: 'Итальянская подкатегория (по умолчанию)',
  PolishSubcategoryDefault: 'Польская подкатегория (по умолчанию)',
  priorSignatures: 'Предыдущие подписи',
  damageWaiverSignature: 'Подпись отказа от ответственности за повреждения',
  waterReleaseSignature: 'Подпись выпуска воды',
  errors,
  themes,
  isPDFRecipientListEnable: 'Включить список получателей PDF на экране подписи клиента',
  isRejectLoadsEnable: 'Включить отклонение загрузок после прибытия на объект',
  rejectLoad_returnedMaterial: 'Кнопка рабочего процесса возвращенного материала',
  rejectLoad_returnedAndFinalize: 'Кнопки рабочего процесса возвращенного материала и завершения',
  driverRejection: 'Отклонить загрузку',
  submitRejection: 'Отправить отклонение',

  costAndPaymentsSummary: 'Сводка по затратам и платежам',
  subtotal: 'Промежуточный итог',
  tax: 'Налог',
  remainingBalance: 'Оставшийся баланс',
  paymentAmount: 'Сумма платежа',
  total: 'Итого',
  paymentMethod: 'Способ оплаты',
  paymentProducts: 'Продукты',
  unitPrice: 'Цена за единицу',
  amount: 'Сумма',
  otherPaymentMethod: 'Другой способ оплаты',
  method: 'Метод',
  paymentOptions: 'Варианты оплаты',
  typeAdditionalInfo: 'Введите дополнительную информацию здесь',
  COD: 'Наличные при доставке',
  AMEX: 'American Express',
  CASH: 'Наличные',
  CHECK: 'Чек',
  'CREDIT CARD': 'Кредитная карта',
  ACCOUNT: 'Открытый счет',
  UNKNOWN: 'Неизвестно',
  remainingMaterial: 'Оставшийся материал',
  splitLoad: 'Разделенная загрузка - несколько',
  isEnablePaymentAndCostWorkflows: 'Включить рабочие процессы оплаты и затрат',

  // DOT Workflow
  dotWorkflow: 'Детали партии',
  inspectorSignature: 'Подпись инспектора',
  producerSignature: 'Подпись производителя',
  addedItems: 'Добавленные элементы',
  producerSignatureContact: 'Имя производителя',
  inspectorSignatureContact: 'Имя инспектора',

  //
  airTempValue: 'Температура воздуха',
  concreteTempValue: 'Температура бетона',
  cylinderNumber: 'Номер цилиндра',
  materialOnTruck: 'Материал на грузовике',
  waterLocation: 'Местоположение воды',
  waterAtPlant: 'Вода на заводе (гал)',
  waterAtJobsite: 'Вода на объекте (гал)',
  totalActualWater: 'Общее фактическое количество воды',
  timeDischarged: 'Время разгрузки',
  // news
  ingredient: 'Ингредиент',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Свободная влага',
  cydsTarg: 'Целевая CYDS',
  target: 'Цель',
  actual: 'Фактическое',
  freWat: 'Свободная вода',
  trim: 'Тримминг',
  batchInformation: 'Информация о партии',
  numberOfBatches: 'Количество партий',
  totalWater: 'Общее количество воды',
  designWater: 'Проектное количество воды',
  toAdd: 'Добавить',
  designWC: 'Проектное W/C',
  waterCement: 'Вода / Цемент',
  waterInTruck: 'Вода в грузовике',
  adjustWater: 'Регулировка воды',
  trimWater: 'Тримминг воды',
  plantWeather: 'Погода на заводе',
  rh: 'RH',
  observationTime: 'Время наблюдения',
  address: 'Адрес',
  plant: 'Завод',
  sp: 'S.P. #',
  beginTime: 'Время начала',
  mixDesc: 'Описание смеси',
  qtyOder: 'Количество заказа',
  poTag: 'PO #',
  bridge: 'Мост #',
  volume: 'Объем',
  truckTag: 'Грузовик #',
  qtyDelivered: 'Количество доставлено',
  qtyOrder: 'Количество заказа',
  job: 'Работа #',
  batchRecord: 'Запись партии',
  mixID: 'ID смеси',
  loads: 'Загрузки',
  isDot: 'Является DOT',
  tolStatus: 'Статус TOL',
  lastUpdate: 'Последнее обновление',
  driverClickedFinalize: 'Водитель нажал завершить',
  autoSubmit: 'Автоматическая отправка',
  noTaxInformationIsAvailable: 'Нет доступной информации о налогах',
  retryPullTaxInformation: 'Обновить данные о налогах',
  var: '% Разница',
  designQTY: 'Проектное количество',
  batched: 'Смешано',
  moisture: 'Влажность',
  actualWater: 'Фактическое количество воды',
  moisturePercent: '% Влажности',
  freeWater: 'Свободная вода',
  reset: 'Сброс',
  'at-plant': 'На заводе',
  'at-job': 'На объекте',
};
