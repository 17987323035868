import cn from 'classnames';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const { Column, Row, Container } = Layout;
const { Translate } = Localization;

const Styled = styled.div`
  .sensorUnable {
    color: #ff0000;
  }
`;

export const ReturnedMaterialsMobile = ({
  mixInfo,
  concreteOnTruckUom,
  disabled,
  options,
  reasonCodeSetup,
  sensorUnable,
  sourceOptions,
  showSource,
  showRejectLoadButton,
  onClickRejection,
  isLoading,
}) => {
  const {
    Components: { ReturnReason, ReturnConcreteOnTruck, DriverDidNotAdd, ReturnConcreteSource },
    values,
  } = Form.useFormContext();

  const [isVisible, setIsVisible] = React.useState(true);
  const onClick = React.useCallback(() => {
    setIsVisible(!isVisible);
  }, [setIsVisible, isVisible]);

  return (
    <Styled className={cn('returned-materials')}>
      <Core.Spinner spin={isLoading}>
        <Column>
          <Row>
            <Container flex={1}>
              <Column>
                <div className={cn('body')}>
                  <Translate stringId="mixIdLabel" data-testid="mixIdDescriptionLabel" /> :{' '}
                  <span data-testid="mixIdDescriptionValue"> {mixInfo.mix} </span>
                </div>
                <div className={cn('body')}>
                  <Translate stringId="loadedQtyLabel" data-testid="loadedQtyLabel" /> :{' '}
                  <span data-testid="loadedQtyValue"> {mixInfo.loadedQty} </span>
                </div>
              </Column>
            </Container>
          </Row>
          {!isVisible && (
            <Row>
              <Container flex={1}>
                <div>
                  <Column>
                    <div className="body">
                      <Translate stringId="remainingMaterial" data-testid="remainMaterailLabel" /> :{' '}
                      <span data-testid="remainMaterialValue">
                        {' '}
                        <Localization.Uom
                          uom={
                            values?.returnConcreteOnTruck?.value
                              ? values?.returnConcreteOnTruck
                              : { value: '0', uomCode: values?.returnConcreteOnTruck?.uomCode }
                          }
                        />
                      </span>
                    </div>
                    <div className="body">
                      <Translate stringId="reason" data-testid="reasonLabel" /> :{' '}
                      <span data-testid="reasonValue"> {values?.returnReason?.description} </span>
                    </div>
                    <div className="row">
                      <div className="body">
                        <Translate stringId="source" data-testid="sourceLabel" /> :{' '}
                        <span data-testid="sourceValue"> {values?.returnConcreteSource?.id} </span>
                      </div>
                      <div className="body">
                        <Core.Button
                          style={{ float: 'right', padding: '0px 50px' }}
                          stringId="edit"
                          onClick={onClick}
                          type="primary"
                          data-testid="edit-button"
                        />
                      </div>
                    </div>
                  </Column>
                </div>
              </Container>
            </Row>
          )}
          {isVisible && (
            <div>
              {' '}
              <Column>
                <ReturnConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
              </Column>
              <Column>
                {sensorUnable && (
                  <Typography.Subtitle className={cn('sensorUnable')}>
                    <Translate stringId="sensorUnable" />
                  </Typography.Subtitle>
                )}
                {options?.length > 0 && (
                  <ReturnReason
                    options={options}
                    busy={reasonCodeSetup.isLoading}
                    showSearch={false}
                    disabled={disabled}
                  />
                )}
                {showSource && <ReturnConcreteSource options={sourceOptions} disabled={true} />}
              </Column>
            </div>
          )}
          {isVisible && (
            <Row>
              <Column>
                <DriverDidNotAdd />
              </Column>
            </Row>
          )}
          {showRejectLoadButton && isVisible && (
            <div>
              <Core.Button
                danger
                stringId="reject"
                onClick={onClickRejection}
                type="primary"
                data-testid="reject-button"
              />
              <Core.Button
                style={{ float: 'right', padding: '0px 50px' }}
                stringId="done"
                onClick={onClick}
                type="primary"
                data-testid="done-button"
              />
            </div>
          )}
        </Column>
      </Core.Spinner>
    </Styled>
  );
};
