import React, { useEffect, useMemo } from 'react';
import { Form, Responsive } from 'connex-cds';
import { useMaterialComplianceSetup } from '../../../MasterDataProvider';
import { MaterialComplianceMobile } from './MaterialComplianceMobile';
import { MaterialComplianceDesktop } from './MaterialComplianceDesktop';

const filterCompliance = type => compliance => compliance?.classType === type;

export const MaterialCompliance = () => {
  const materialComplianceSetup = useMaterialComplianceSetup();

  const { values, setFieldValue } = Form.useFormContext();

  const loadingMaterialCompliance = useMemo(
    () => materialComplianceSetup?.isLoading,
    [materialComplianceSetup?.isLoading]
  );

  const activeMaterialComplianceSetup = useMemo(() => {
    if (materialComplianceSetup?.isSuccess && materialComplianceSetup?.data?.length) {
      return materialComplianceSetup?.data?.filter?.(compliance => compliance?.status === 'ACTIVE');
    }

    return [];
  }, [materialComplianceSetup?.data, materialComplianceSetup.isSuccess]);

  const air = useMemo(
    () =>
      [
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('air')),
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('AIR')),
      ]?.[0],
    [activeMaterialComplianceSetup]
  );

  const consistence = useMemo(
    () =>
      [
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('consistence')),
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('CONSISTENCE')),
      ]?.[0],
    [activeMaterialComplianceSetup]
  );

  const airTemp = useMemo(
    () =>
      [
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('airTemperature')),
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('AIR_TEMP')),
      ]?.[0],
    [activeMaterialComplianceSetup]
  );

  const concreteTemp = useMemo(
    () =>
      [
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('temperature')),
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('CONCRETE_TEMP')),
      ]?.[0],
    [activeMaterialComplianceSetup]
  );

  const cylinder = useMemo(
    () =>
      [
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('cylinders')),
        ...activeMaterialComplianceSetup?.filter?.(filterCompliance('CYLINDERS')),
      ]?.[0],
    [activeMaterialComplianceSetup]
  );

  const consistenceDisabled = useMemo(() => {
    return !values?.productCompliance?.consistenceValue?.value;
  }, [values?.productCompliance?.consistenceValue]);

  const airDisabled = useMemo(() => {
    return !values?.productCompliance?.airContentValue?.value;
  }, [values?.productCompliance?.airContentValue]);

  const airTempDisabled = useMemo(() => {
    return !values?.productCompliance?.airTempValue?.value;
  }, [values?.productCompliance?.airTempValue]);

  const concreteTempDisabled = useMemo(() => {
    return !values?.productCompliance?.concreteTempValue?.value;
  }, [values?.productCompliance?.concreteTempValue]);

  const cylinderNumberDisabled = useMemo(() => {
    return !values?.productCompliance?.cylinderNumber?.value;
  }, [values?.productCompliance?.cylinderNumber]);

  const materialComplianceNotFound = useMemo(
    () => [air, consistence, airTemp, concreteTemp, cylinder].every(material => !material),
    [air, consistence, airTemp, concreteTemp, cylinder]
  );

  useEffect(() => {
    if (!consistenceDisabled || !airDisabled || !airTempDisabled || !concreteTempDisabled || !cylinderNumberDisabled) {
      setFieldValue('productCompliance.dateTest', new Date().toISOString());
    }
  }, [consistenceDisabled, airDisabled, airTempDisabled, concreteTempDisabled, cylinderNumberDisabled]);

  return (
    <Responsive>
      <MaterialComplianceMobile
        loadingMaterialCompliance={loadingMaterialCompliance}
        air={air}
        consistence={consistence}
        airTemp={airTemp}
        concreteTemp={concreteTemp}
        cylinder={cylinder}
        consistenceDisabled={consistenceDisabled}
        airDisabled={airDisabled}
        materialComplianceNotFound={materialComplianceNotFound}
      />
      <MaterialComplianceDesktop
        loadingMaterialCompliance={loadingMaterialCompliance}
        air={air}
        consistence={consistence}
        airTemp={airTemp}
        concreteTemp={concreteTemp}
        cylinder={cylinder}
        consistenceDisabled={consistenceDisabled}
        airDisabled={airDisabled}
        materialComplianceNotFound={materialComplianceNotFound}
      />
    </Responsive>
  );
};
