import React, { useCallback, useContext, useMemo, useState } from 'react';
import cn from 'classnames';

import { Divider, Box, Collapse, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { DrawerProvider } from '../../components/drawer';
import { Translate } from '../../components/localization';
import { MaterialIcon } from '../../components/icons';
import { AppSwitcher } from './app-switcher/AppSwitcher';
import { Profile } from './profile/Profile';
import MenuIcon from './menu-icon/MenuIcon';
import { AppContext } from '../../util/app-provider/context';
import EntityPicker from './entity-picker';
import ProgressBar from './progress-bar';
import SearchField from './search-field';
import TitleMenu from './title-menu';
import { HeaderContainer } from './style';

import { useChatContext } from '../../components/chat';
import useQueryControls from './useQueryControls';
import { useBreakpoints } from '../../hooks/useBreakpoints';

const learningCenterAppRef = 'learning-center';
const platformAdminAppRef = 'admin';
const maxUnreadMsgCounter = '99+';

export const Header = ({
  className,
  appContext,
  entityContext,
  userContext,
  onChange,
  onProfileClick,
  disableAppTray,
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { entitySetup } = entityContext;
  const { userApps, userAppsIsLoading, user, hasPermission } = userContext;
  const { isXl, isMd } = useBreakpoints();

  const cdsContext = useContext(AppContext);
  const { handleChatOpen, isChatOpened, handleChatClose, showNotification, totalUnreadMessages } = useChatContext();
  const {
    appEntities,
    currentFeature,
    headerText,
    headerControls,
    appRef,
    headerConfig: contextHeaderConfig,
  } = appContext;
  const { entityRef, setEntityRef } = entityContext;
  const headerConfig = useMemo(() => (cdsContext && contextHeaderConfig) || {}, [contextHeaderConfig, cdsContext]);
  const { searchControl, dateControl } = useQueryControls(headerConfig?.queryControls || []);

  const formattedUnreadMessagesCount = totalUnreadMessages > 99 ? maxUnreadMsgCounter : totalUnreadMessages?.toString();
  const disableMobileSidebar = !cdsContext || !cdsContext.toggleSidebar;
  const isSuccessCenter = appRef === learningCenterAppRef;
  const isPlatformAdmin = appRef === platformAdminAppRef;

  const hasChatSKU = useMemo(() => {
    const entitlements = entityContext?.entitlements?.entitlements || [];
    return entitlements?.some?.(etl => etl?.skuRef === 'chat-preview');
  }, [entityContext]);

  const hasChatPermission = useMemo(() => {
    return hasPermission('chat-view');
  }, [hasPermission]);

  const headerTitle = useMemo(() => {
    return (
      <>
        {currentFeature?.iconName ? (
          <MaterialIcon iconName={currentFeature?.iconName} className="feature-icon" />
        ) : null}
        {headerText ||
          (currentFeature ? (
            <Translate
              data-testid="feature-title"
              stringId={currentFeature.labelStringId}
              className={cn('feature-title')}
            />
          ) : (
            ''
          ))}
      </>
    );
  }, [currentFeature, headerText]);

  const showChatIcon = (!isSuccessCenter || !isPlatformAdmin) && hasChatPermission && hasChatSKU;

  const handleEntityChange = useCallback(
    (entityRef, _companyName) => {
      setEntityRef(entityRef);
      handleChatClose();
    },
    [setEntityRef, handleChatClose]
  );

  const toggleSearch = () => {
    setIsSearchOpen(prevState => !prevState);
  };

  const featureIcon =
    headerConfig?.tabsConfig?.customFeatureIcon ||
    (currentFeature?.iconName && <MaterialIcon iconName={currentFeature?.iconName} className="feature-icon" />);

  return (
    <HeaderContainer className={cn({ 'is-mobile': !isXl, 'disable-mobile-sidebar': disableMobileSidebar })}>
      <DrawerProvider>
        <div className={cn('header', className)}>
          {!isXl && !isMd && cdsContext && dateControl && (
            <Box {...(!isMd && { sx: { marginTop: '0.5rem' } })}>{dateControl.component}</Box>
          )}

          {!isXl && !isMd && cdsContext && searchControl && (
            <Collapse in={isSearchOpen}>
              <SearchField
                component={searchControl.component}
                props={{ ...searchControl.props, toggleSearch }}
                sx={{ width: '100%' }}
              />
            </Collapse>
          )}

          <Box
            className={cn('left', { visible: !!currentFeature?.id })}
            {...((headerConfig?.queryControls || headerConfig?.actions) && {
              sx: { display: 'flex', justifyContent: 'space-between' },
            })}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', flexGrow: 1 }}>
              {!isXl && cdsContext && headerConfig?.onArrowBackClick && (
                <MaterialIcon
                  className={'arrow-back-icon'}
                  iconName={'ArrowBackIosRounded'}
                  onClick={headerConfig?.onArrowBackClick}
                  sx={{ height: '28px', width: '28px' }}
                />
              )}
              <div data-testid="feature-name" className={cn('feature-name')}>
                {!isXl && headerConfig?.tabsConfig && headerConfig.tabsConfig?.tabs?.length > 0 ? (
                  <TitleMenu
                    menuItems={headerConfig.tabsConfig.tabs}
                    setCurrentTab={headerConfig.tabsConfig.setCurrentTab}
                    currentTab={headerConfig.tabsConfig.currentTab}
                    icon={featureIcon}
                  />
                ) : (
                  headerTitle
                )}
              </div>
              {!isXl && cdsContext && headerConfig?.progressBarValue && (
                <ProgressBar value={headerConfig?.progressBarValue} className="progress-bar" />
              )}
              {isMd && !isXl && cdsContext && searchControl && (
                <SearchField component={searchControl.component} props={{ ...searchControl.props, toggleSearch }} />
              )}
            </Box>

            {headerControls}

            {!isXl && cdsContext && headerConfig && (headerConfig?.queryControls || headerConfig?.actions) && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: isSearchOpen ? '0' : '10px' }}>
                {headerConfig && headerConfig?.actions?.length > 0 && (
                  <Box className="action-buttons" sx={{ display: 'flex', gap: '8px', marginLeft: 'auto' }}>
                    {headerConfig.actions}
                  </Box>
                )}

                {headerConfig?.queryControls?.length > 0 && (
                  <Box className="query-controls" sx={{ display: 'flex', gap: '8px', marginLeft: 'auto' }}>
                    {isMd && dateControl?.component}
                    {!isMd && !isXl && cdsContext && searchControl && (
                      <Collapse in={!isSearchOpen} orientation="horizontal">
                        <IconButton
                          onClick={toggleSearch}
                          aria-label="expand-search"
                          className="collapse-search-button"
                        >
                          <SearchIcon />
                        </IconButton>
                      </Collapse>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {!isXl && cdsContext && <Divider component="hr" className="header-divider" aria-hidden="true" />}

          <div className="right">
            <div className="center-aligned">
              {!disableMobileSidebar && cdsContext.toggleSidebar && (
                <MenuIcon
                  iconName="Menu"
                  onClick={cdsContext.toggleSidebar}
                  variant="secondary"
                  className={cn('mobile-menu-icon')}
                />
              )}
              {!disableAppTray && (
                <>
                  {appEntities?.length > 0 && (
                    <EntityPicker
                      currentEntitySetup={entitySetup}
                      value={entityRef}
                      onChange={handleEntityChange}
                      entities={appEntities}
                      className="entity-picker"
                    />
                  )}
                  <MenuIcon
                    iconName="HelpOutlineOutlined"
                    active={appRef === learningCenterAppRef}
                    onClick={() => onChange(learningCenterAppRef, true)}
                    variant="secondary"
                    disableClick={appRef === learningCenterAppRef}
                  />
                  {showChatIcon && (
                    <div className="chat-icon-container">
                      <MenuIcon
                        active={isChatOpened}
                        iconName="ChatOutlined"
                        onClick={isChatOpened ? handleChatClose : handleChatOpen}
                        variant="secondary"
                      >
                        {showNotification && (
                          <div
                            className={cn('chat-icon-badge', {
                              'max-unread-messages ': formattedUnreadMessagesCount === maxUnreadMsgCounter,
                            })}
                          >
                            {formattedUnreadMessagesCount}
                          </div>
                        )}
                      </MenuIcon>
                    </div>
                  )}
                  <AppSwitcher
                    onChange={onChange}
                    userApps={userApps}
                    isLoading={userAppsIsLoading}
                    appContext={appContext}
                  />
                </>
              )}
              <Profile onClick={onProfileClick} user={user} />
            </div>
          </div>
        </div>
      </DrawerProvider>
    </HeaderContainer>
  );
};
