import React from 'react';
import cn from 'classnames';
import { Core, Form, Layout, Localization } from 'connex-cds';
import styled from 'styled-components';
import style from '../style';

const { Column, Row, Container } = Layout;
const { useTranslateObjects } = Localization;

const Styled = styled.div`
  ${style}

  div[data-testid="consistenceValue-form-field"] label.label, 
  div[data-testid="airContentValue-form-field"] label.label,
  div[data-testid="airTempValue-form-field"] label.label,
  div[data-testid="concreteTempValue-form-field"] label.label,
  div[data-testid="cylinderNumber-form-field"] label.label {
    display: none;
  }

  label.translate-label {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const MaterialComplianceDesktop = ({
  loadingMaterialCompliance,
  air,
  consistence,
  airTemp,
  concreteTemp,
  cylinder,
  consistenceDisabled,
  airDisabled,
  materialComplianceNotFound,
}) => {
  const {
    Components: {
      ConsistenceValue,
      ConsistenceIsCompliant,
      AirContentValue,
      AirContentIsCompliant,
      DateTest,
      AirTempValue,
      ConcreteTempValue,
      CylinderNumber,
      AdditionalInfo,
    },
  } = Form.useFormContext();
  const { translateObjects } = useTranslateObjects();

  const translateLabel = materialCompliance => {
    const materialComplianceTranslated = translateObjects([materialCompliance], {
      getStringId: id => `SETUP_CONCRETE-CLASSES_${id}-NAME`.replace(/\s/gi, '_').toUpperCase(),
      getPath: 'id',
      setPath: 'label',
      defaultMessagePath: 'name',
    });

    return `${materialComplianceTranslated?.[0]?.label}:`;
  };

  return (
    <Styled className={cn('material-compliance')}>
      <Container>
        <Column>
          <Core.Spinner spin={loadingMaterialCompliance}>
            {consistence && (
              <>
                <Row>
                  <Column>
                    <label className="translate-label">{translateLabel(consistence)}</label>
                    <ConsistenceValue uomCode={consistence?.uomCode} />
                  </Column>
                </Row>
                {consistence?.checkCompliance && (
                  <Row>
                    <Column>
                      <ConsistenceIsCompliant disabled={consistenceDisabled} />
                    </Column>
                  </Row>
                )}
              </>
            )}
            {air && (
              <>
                <Row>
                  <Column>
                    <label className="translate-label">{translateLabel(air)}</label>
                    <AirContentValue uomCode={air?.uomCode} />
                  </Column>
                </Row>
                {air?.checkCompliance && (
                  <Row>
                    <Column>
                      <AirContentIsCompliant disabled={airDisabled} />
                    </Column>
                  </Row>
                )}
              </>
            )}
            {airTemp && (
              <Row>
                <Column>
                  <label className="translate-label">{translateLabel(airTemp)}</label>
                  <AirTempValue uomCode={airTemp?.uomCode} />
                </Column>
              </Row>
            )}
            {concreteTemp && (
              <Row>
                <Column>
                  <label className="translate-label">{translateLabel(concreteTemp)}</label>
                  <ConcreteTempValue uomCode={concreteTemp?.uomCode} />
                </Column>
              </Row>
            )}
            {cylinder && (
              <Row>
                <Column>
                  <label className="translate-label">{translateLabel(cylinder)}</label>
                  <CylinderNumber uomCode={cylinder?.uomCode} />
                </Column>
              </Row>
            )}
            {[air, consistence, airTemp, concreteTemp, cylinder].some(Boolean) && (
              <>
                <Row>
                  <Column>
                    <AdditionalInfo rows={3} validationText={false} />
                  </Column>
                </Row>

                <Row>
                  <Column>
                    <DateTest />
                  </Column>
                </Row>
              </>
            )}

            {materialComplianceNotFound && (
              <Row>
                <Column className="material-compliance-not-found">
                  <Localization.Translate
                    stringId="materialCompliance_notFound"
                    data-testid="materialCompliance_notFound"
                  />
                </Column>
              </Row>
            )}
          </Core.Spinner>
        </Column>
      </Container>
    </Styled>
  );
};
